import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { Title } from '@angular/platform-browser';

import { Location } from '@angular/common';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  resetPassForm: FormGroup;
  loading = false;
  errMess: string;
  resMess: string;
  returnUrl: string;

  @ViewChild('fform') quizFormDirective;

  formErrors = {
    'email': '',
  };
  validationMessages = {
    'email': {
      'required': 'Email is required',
      'email': 'Email not in valid format.'
    },
  };

  constructor(public authService: AuthService,
    private fb: FormBuilder,
    private titleService: Title,
    public afAuth: AngularFireAuth,
    private _location: Location,
    private route: ActivatedRoute) {
    this.titleService.setTitle("Halloumoğlu - Parola Sıfırlama");
  }

  ngOnInit(): void {
    this.createForm();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  createForm() {
    this.resetPassForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.resetPassForm.valueChanges
      .subscribe(data => this.onValueChanged(data));

    this.onValueChanged(); //(re)set form validation messages 
  }

  onValueChanged(data?: any) {
    if (!this.resetPassForm) {
      return;
    }
    const form = this.resetPassForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous erroe message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  resetPassword(email) {
    return this.afAuth.sendPasswordResetEmail(email)
      .then((res) => {
        this.loading = false;
        return res
      }).catch((error) => {
        throw error
      })
  }

  reset(email) {
    this.loading = true;
    this.resetPassword(email).then(
      res => {
        this.errMess = null;
        this.resMess = 'Parolanızı sıfırlamanız için size bir E-posta gönderdik—lütfen E-posta gelen kutunuzu kontrol edin!';
      },
      error => {
        this.loading = false;
        this.resMess = null;
        if (error.code == "auth/invalid-email") {
          this.errMess = 'Geçersiz E-posta formatı';
        }
        else if (error.code == "auth/too-many-requests") {
          this.errMess = 'Birden çok şifre sıfırlama denemesi, lütfen daha sonra tekrar deneyin';
        }
        else if (error.code == "auth/user-not-found") {
          this.errMess = 'Hesap bulunamadı';
        }
        else {
          this.errMess = error.message;
        }
      })
  }

  backClicked() {
    console.log(this._location.getState()["navigationId"]);
  }

}
