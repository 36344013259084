<app-header></app-header>
<main class="main">
  <div class="container-fluid">
    <div class="col-xl-11 col-md-12 center">
      <div class="row mb-4">
        <div class="col-xl-2 col-lg-2 col-md-4 mt-2">
          <app-navigator-list></app-navigator-list>
        </div>
        <div
          class="col-xl-10 col-lg-10 col-md-8 mt-2 mb-2"
          *ngIf="!spinner && payment"
        >
          <div class="text-center poppins" style="font-size: 1.75rem">
            <p class="poppins" style="color: #00c851">
              İşlem Başarıyla Tamamlandı
              <i class="fas fa-check-circle" style="color: #00c851"></i>
            </p>
          </div>
          <div
            class="alert alert-primary poppins"
            role="alert"
            data-mdb-color="primary"
          >
            <i class="fab fa-envira me-3"></i>
            Her zaman çevre dostu araçları desteklediğimiz için, kargonuzla
            birlikte bir fiş göndereceğiz veya e-postanıza bir fatura
            gönderilecektir. Not: Faturalandırma e-postanızı bulamazsanız,
            lütfen önemsiz/diğer postalar kutunuzdaki e-postayı kontrol edin.
          </div>
          <div class="card mt-4 mb-4">
            <div
              class="card-header"
              style="background-color: rgba(0, 0, 0, 0.03) !important"
            >
              <div class="row">
                <div class="col-lg-3 col-md-6 col-6 text-center poppins">
                  <p
                    style="
                      font-size: 13px;
                      margin-bottom: 3px !important;
                      color: #00000085;
                    "
                  >
                    Sipariş tarihi
                  </p>
                  <p
                    style="
                      font-size: 13px;
                      margin-bottom: 0px !important;
                      color: #000;
                    "
                  >
                    {{ payment.createdAt | date : "dd/MM/yyyy, h:mm:ss a" }}
                  </p>
                </div>
                <div class="col-lg-3 col-md-6 col-6 text-center poppins">
                  <p
                    style="
                      font-size: 13px;
                      margin-bottom: 3px !important;
                      color: #00000085;
                    "
                  >
                    Alıcı
                  </p>
                  <p
                    style="
                      font-size: 13px;
                      margin-bottom: 0px !important;
                      color: #000;
                    "
                  >
                    {{ user.name | uppercase }} {{ user.lastname | uppercase }}
                  </p>
                </div>
                <div class="col-lg-2 col-md-6 col-6 text-center poppins">
                  <p
                    style="
                      font-size: 13px;
                      margin-bottom: 3px !important;
                      color: #00000085;
                    "
                  >
                    Sipariş No
                  </p>
                  <p
                    style="
                      font-size: 13px;
                      margin-bottom: 0px !important;
                      color: #000;
                    "
                  >
                    {{ payment.cartId }}
                  </p>
                </div>
                <div class="col-lg-2 col-md-6 col-6 text-center poppins">
                  <p
                    style="
                      font-size: 13px;
                      margin-bottom: 3px !important;
                      color: #00000085;
                    "
                  >
                    Toplam
                  </p>
                  <p
                    class="text-gold"
                    style="
                      font-size: 15px;
                      margin-bottom: 0px !important;
                      font-weight: 500;
                    "
                  >
                    {{
                      payment.price | currency : " " + payment.currency + " "
                    }}
                  </p>
                </div>
                <div class="col-lg-2 col-md-6 col-6 text-center poppins">
                  <p
                    style="
                      font-size: 13px;
                      margin-bottom: 3px !important;
                      color: #00000085;
                    "
                  >
                    Detaylar
                  </p>
                  <p
                    style="
                      font-size: 13px;
                      margin-bottom: 0px !important;
                      color: #000;
                    "
                  >
                    {{ payment.itemTransactions.length }} Ürün
                  </p>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div *ngFor="let product of myOrders">
                <div class="row" style="align-items: center">
                  <div class="col-lg-2 col-md-4 col-6 center">
                    <img
                      [src]="product.image"
                      class="center swiper-image"
                      style="border-radius: 4px !important; width: 55%"
                    />
                  </div>
                  <div class="col-lg-5 col-md-4 col-6 center">
                    <span class="text-uppercase text-muted brand">
                      {{ product.markaTR | uppercase }}
                    </span>
                    <a [routerLink]="'/product/' + product.id">
                      <h6
                        class="text-uppercase mt-1 text-green"
                        style="font-size: 13px"
                      >
                        <b>{{ product.nameTR }}</b>
                      </h6>
                    </a>
                  </div>
                  <div class="col-lg-3 col-md-4 center">
                    <div
                      class="price d-flex flex-row align-items-center"
                      style="font-size: 15px"
                    >
                      <span class="act-price">
                        {{
                          product.price
                            | currency : " " + payment.currency + " "
                        }}
                        x {{ product.count }} =
                        {{
                          product.price * product.count
                            | currency : " " + payment.currency + " "
                        }}</span
                      >
                    </div>
                  </div>
                </div>
                <hr style="width: 75%" class="center" />
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-xl-10 col-lg-10 col-md-8 mt-2 mb-2"
          *ngIf="!spinner && !payment && errMess"
        >
          <div class="card card-body">
            <h1 class="text-center poppins">
              <i class="far fa-frown"></i>
              <br />
              {{ errMess.status }}
              <br />
              {{ errMess.errorMessage }}
              <br />
              <br />
              <a routerLink="/"
                >Ana sayfaya geri dön <i class="fas fa-external-link-alt"></i
              ></a>
            </h1>
          </div>
        </div>
        <div class="col-xl-10 col-lg-10 col-md-8 mt-2 mb-2" *ngIf="spinner">
          <mat-spinner style="margin-top: 15px" class="center"> </mat-spinner>
        </div>
      </div>
    </div>
  </div>
</main>
<app-footer></app-footer>
