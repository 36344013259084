import { Component, OnInit, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatExpansionPanel } from "@angular/material/expansion";

@Component({
  selector: "app-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
  styleUrls: ["./privacy-policy.component.scss"],
})
export class PrivacyPolicyComponent implements OnInit {
  privacy: any;
  isMode: boolean = false;
  selectedPanel = 0;

  itemsTable: string = `
  <table class="MsoTableGrid" border="1" cellspacing="0" cellpadding="0" style="border: none;">
  <tbody>
      <tr style="mso-yfti-irow:0;mso-yfti-firstrow:yes;height:41.05pt">
          <td width="199" valign="top" style="width:149.6pt;border:solid windowtext 1.0pt;
   mso-border-alt:solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt;height:41.05pt">
              <p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
   line-height:normal"><b><span style="font-size:12.0pt"><b>Ürün adı</b>
                          <o:p></o:p>
                      </span></b></p>
          </td>
          <td width="200" valign="top" style="width:149.65pt;border:solid windowtext 1.0pt;
   border-left:none;mso-border-left-alt:solid windowtext .5pt;mso-border-alt:
   solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt;height:41.05pt">
              <p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
   line-height:normal"><b><span style="font-size:12.0pt"><b>Miktar</b>
                          <o:p></o:p>
                      </span></b></p>
          </td>
          <td width="200" valign="top" style="width:149.65pt;border:solid windowtext 1.0pt;
   border-left:none;mso-border-left-alt:solid windowtext .5pt;mso-border-alt:
   solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt;height:41.05pt">
              <p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
   line-height:normal"><b><span style="font-size:12.0pt"><b>Toplam</b>
                          <o:p></o:p>
                      </span></b></p>
          </td>
      </tr>
      <tr style="mso-yfti-irow:0;mso-yfti-firstrow:yes;height:41.05pt">
          <td width="199" valign="top" style="width:149.6pt;border:solid windowtext 1.0pt;
   mso-border-alt:solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt;height:41.05pt">
              <p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
   line-height:normal"><b><span style="font-size:12.0pt">
                          <o:p></o:p>
                      </span></b></p>
          </td>
          <td width="200" valign="top" style="width:149.65pt;border:solid windowtext 1.0pt;
   border-left:none;mso-border-left-alt:solid windowtext .5pt;mso-border-alt:
   solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt;height:41.05pt">
              <p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
   line-height:normal"><b><span style="font-size:12.0pt">
                          <o:p></o:p>
                      </span></b></p>
          </td>
          <td width="200" valign="top" style="width:149.65pt;border:solid windowtext 1.0pt;
   border-left:none;mso-border-left-alt:solid windowtext .5pt;mso-border-alt:
   solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt;height:41.05pt">
              <p class="MsoNormal" align="center" style="margin-bottom:0in;text-align:center;
   line-height:normal"><b><span style="font-size:12.0pt">
                          <o:p></o:p>
                      </span></b></p>
          </td>
      </tr>
    </tbody> </table>`;
  cartId: string = "";
  shippingCost: string = "";
  orderDate: string = "";
  deliveryDate: string = "";
  total: string = "";
  buyerName: string = "";
  buyerAddress: string = "";
  buyerPhone: string = "";
  buyerEmail: string = "";
  contactName: string = "";
  shippingAddress: string = "";
  shippingPhone: string = "";
  billingContactName: string = "";
  billingAddress: string = "";
  billingPhone: string = "";
  spinner: boolean = false;

  constructor(
    private router: Router,
    public db: AngularFirestore,
    private route: ActivatedRoute,
    private title: Title
  ) {
    this.title.setTitle("Halloumoğlu - Gizlilik Politikası");
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.db.firestore
      .collection("content")
      .doc("privacyTR")
      .get()
      .then((documentSnapshots: any) => {
        let data = documentSnapshots.data();
        data.turkish[3].description = data.turkish[3].description
          .replace(/{{itemsTable}}/g, this.itemsTable)
          .replace(/{{shippingCost}}/g, this.shippingCost)
          .replace(/{{orderDate}}/g, this.orderDate)
          .replace(/{{deliveryDate}}/g, this.deliveryDate)
          .replace(/{{total}}/g, this.total)
          .replace(/{{buyerName}}/g, this.buyerName)
          .replace(/{{buyerAddress}}/g, this.buyerAddress)
          .replace(/{{buyerPhone}}/g, this.buyerPhone)
          .replace(/{{contactName}}/g, this.contactName)
          .replace(/{{buyerEmail}}/g, this.buyerEmail)
          .replace(/{{shippingAddress}}/g, this.shippingAddress)
          .replace(/{{shippingPhone}}/g, this.shippingPhone)
          .replace(/{{billingContactName}}/g, this.billingContactName)
          .replace(/{{billingAddress}}/g, this.billingAddress)
          .replace(/{{billingPhone}}/g, this.billingPhone);

        data.turkish[4].description = data.turkish[4].description
          .replace(/{{cartId}}/g, this.cartId)
          .replace(/{{itemsTable}}/g, this.itemsTable)
          .replace(/{{shippingCost}}/g, this.shippingCost)
          .replace(/{{orderDate}}/g, this.orderDate)
          .replace(/{{deliveryDate}}/g, this.deliveryDate)
          .replace(/{{total}}/g, this.total)
          .replace(/{{buyerName}}/g, this.buyerName)
          .replace(/{{buyerAddress}}/g, this.buyerAddress)
          .replace(/{{buyerPhone}}/g, this.buyerPhone)
          .replace(/{{contactName}}/g, this.contactName)
          .replace(/{{buyerEmail}}/g, this.buyerEmail)
          .replace(/{{shippingAddress}}/g, this.shippingAddress)
          .replace(/{{shippingPhone}}/g, this.shippingPhone)
          .replace(/{{billingContactName}}/g, this.billingContactName)
          .replace(/{{billingAddress}}/g, this.billingAddress)
          .replace(/{{billingPhone}}/g, this.billingPhone);
        this.afterReplace(data);
      });

    this.route.queryParams.subscribe((params) => {
      if (params["openPanel"]) {
        this.isMode = true;
        this.selectedPanel = parseInt(params["openPanel"]) - 1;
      } else {
        this.isMode = false;
      }
    });
    setTimeout(() => {
      this.spinner = true;
    }, 2000);
  }

  afterReplace(data) {
    this.privacy = data;
  }
}
