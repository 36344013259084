<app-header></app-header>
<main class="main">
  <div class="container-fluid">
    <div class="col-xl-11 col-md-12 center">
      <div class="row mb-4">
        <div class="col-xl-2 col-lg-2 col-md-3 col-12 mt-2">
          <app-navigator-list></app-navigator-list>
        </div>
        <div
          class="col-xl-10 col-lg-10 col-md-9 col-12 mt-2 mb-2"
          *ngIf="addresses.length > 0"
        >
          <div class="card mb-4">
            <div
              class="card-header"
              style="background-color: rgba(0, 0, 0, 0.03) !important"
            >
              <div class="poppins" style="color: #000">
                <a class="text-green add-address" (click)="openDialog()"
                  ><i class="fas fa-plus text-gold" style="font-size: 18px"></i>
                  Yeni bir adres ekleyin</a
                >
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div
                  class="col-lg-4 col-md-12 mb-2"
                  *ngFor="let address of addresses; let i = index"
                >
                  <div class="card shadow-sm poppins">
                    <div
                      class="card-header"
                      style="background-color: rgba(0, 0, 0, 0.03) !important"
                    >
                      <p style="margin-bottom: 0px">
                        {{ address.payload.doc.data().addressTitle }}
                      </p>
                    </div>
                    <div class="card-body" style="font-size: 13px">
                      <p
                        style="
                          margin-bottom: 0px;
                          font-family: monospace;
                          letter-spacing: 1px;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                      >
                        {{ address.payload.doc.data().name | uppercase }}
                        {{ address.payload.doc.data().lastname | uppercase }}
                        <br />
                        <span
                          style="
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                          "
                        >
                          {{ address.payload.doc.data().address | uppercase }}
                        </span>
                        <br />
                        {{ address.payload.doc.data().district | titlecase }}/{{
                          address.payload.doc.data().city | titlecase
                        }}
                        <br />
                        {{ address.payload.doc.data().phone }}
                      </p>
                    </div>
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-md-9 col-9 center mb-3">
                          <a
                            (click)="openDialogEdit(i)"
                            class="btn btn-outline-warning btn-block mt-1"
                          >
                            <i class="fas fa-edit"></i> Adresi düzelt
                          </a>
                        </div>
                        <div class="col-md-2 col-3 center mt-2 text-center">
                          <a (click)="deleteAddress(address.payload.doc.id)">
                            <i
                              style="color: #dc3545"
                              class="fas fa-trash-alt"
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-xl-10 col-lg-10 col-md-9 col-12 mt-2 mb-2"
          *ngIf="addresses.length == 0"
        >
          <div class="card shadow-sm">
            <div
              class="card-header"
              style="background-color: rgba(0, 0, 0, 0.03) !important"
            >
              <div class="poppins" style="color: #000">
                <a class="text-green add-address" (click)="openDialog()"
                  ><i class="fas fa-plus text-gold" style="font-size: 18px"></i>
                  Yeni bir adres ekleyin</a
                >
              </div>
            </div>
            <div class="card-body">
              <p class="poppins text-center">Henüz eklenmiş bir adres yok!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mat-spinner style="margin-top: 15px" class="center" *ngIf="!user">
    </mat-spinner>
    <!-- <mat-spinner style="margin-top: 15px;" class="center" mode="determinate" value="{{50}}">
        </mat-spinner> -->
  </div>
</main>
<app-footer></app-footer>
