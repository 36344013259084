<main class="main">
  <div class="container">
    <div class="row" *ngIf="status == 'notDone'">
      <div class="col-lg-8 col-md-12 mb-4">
        <div
          class="card card-body shadow"
          style="background-color: #fff; border: none"
        >
          <h3 class="h3-responsive poppins text-center">Yeni hesap oluştur</h3>
          <h5
            class="text-center poppins mt-4"
            *ngIf="errMess"
            style="color: red; font-weight: 600"
          >
            {{ errMess }}
          </h5>
          <form
            class="contact-form mt-4"
            novalidate
            [formGroup]="signUpForm"
            #fform="ngForm"
            (ngSubmit)="onSubmit()"
          >
            <div class="row">
              <div class="col-md-6">
                <mat-form-field
                  class="poppins"
                  appearance="outline"
                  color="primary"
                >
                  <mat-label>Adı</mat-label>
                  <input
                    matInput
                    formControlName="name"
                    type="text"
                    placeholder="Adınızı yazın"
                    required
                  />
                  <mat-error *ngIf="formErrors.name">
                    {{ formErrors.name }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field
                  class="poppins"
                  appearance="outline"
                  color="primary"
                >
                  <mat-label>Soyadı</mat-label>
                  <input
                    matInput
                    formControlName="lastname"
                    type="text"
                    placeholder="Soyadı yazın"
                    required
                  />
                  <mat-error *ngIf="formErrors.lastname">
                    {{ formErrors.lastname }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <mat-form-field
                  class="poppins"
                  appearance="outline"
                  color="primary"
                >
                  <mat-label>E-posta</mat-label>
                  <input
                    matInput
                    formControlName="email"
                    type="email"
                    placeholder="E-posta adresiniz"
                    required
                  />
                  <mat-error *ngIf="formErrors.email">
                    {{ formErrors.email }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field
                  class="poppins"
                  appearance="outline"
                  color="primary"
                >
                  <mat-label>Şifre</mat-label>
                  <input
                    matInput
                    formControlName="password"
                    type="password"
                    placeholder="Şifreniz"
                    required
                  />
                  <mat-error *ngIf="formErrors.password">
                    {{ formErrors.password }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field
                  class="poppins"
                  appearance="outline"
                  color="primary"
                >
                  <mat-label>Şifreyi Onayla</mat-label>
                  <input
                    matInput
                    formControlName="confirmPass"
                    type="password"
                    placeholder="Şifrenizi Onaylayın"
                    required
                  />
                  <mat-error *ngIf="formErrors.confirmPass">
                    Şifreler uyuşmuyor lütfen tekrar deneyin
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12 col-12 mb-2">
                <section class="example-section" style="margin-left: 5px">
                  <mat-checkbox
                    formControlName="checkbox"
                    color="primary"
                    class="example-margin"
                  >
                  </mat-checkbox>
                  <span class="poppins" style="margin-left: 5px">
                    <a routerLink="/membership-agreemant" target="_blank"
                      >Üyelik Sözleşmesini</a
                    >
                    ve
                    <a routerLink="/privacy-policy" target="_blank"
                      >Gizlilik Politikasını</a
                    >
                    okudum ve kabul ediyorum.
                  </span>
                </section>
              </div>
              <div class="col-md-12 col-12 mb-2">
                <section class="example-section" style="margin-left: 5px">
                  <mat-checkbox
                    formControlName="emailSubscription"
                    color="primary"
                    class="example-margin poppins"
                  ></mat-checkbox>
                  <span class="poppins" style="margin-left: 5px">
                    En son tekliflerimizi ve indirimlerimizi e-posta ile alın.
                  </span>
                </section>
              </div>
            </div>
            <div class="col-md-12">
              <button
                [hidden]="loading"
                class="btn btn-primary btn-round btn-block btn-halloum mb-2 poppins"
                [disabled]="!signUpForm.value.checkbox || signUpForm.invalid"
                mdbWavesEffect
                type="submit"
              >
                Üye Ol
              </button>

              <button
                *ngIf="loading"
                class="btn btn-primary btn-round btn-block btn-halloum mb-2 poppins"
                [disabled]="true"
                mdbWavesEffect
                type="submit"
              >
                Hesabınız oluşturuluyor
                <i
                  style="margin-left: 3px"
                  class="fa fa-spinner fa-spin fa-lg"
                ></i>
              </button>
              <div class="poppins">
                Hesabınız var mı?
                <a routerLink="/sign-in"> Giriş Yap </a>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-lg-4 col-md-8 center">
        <div
          class="card card-body shadow"
          style="background-color: #fff; border: none"
        >
          <h3 class="h3-responsive poppins text-center">Yada</h3>
          <hr />
          <a
            class="btn shadow mt-4 mb-4"
            style="background-color: #4285f4; color: #fff"
            (click)="authService.GoogleAuth(returnUrl)"
          >
            <i
              class="fab fa-google"
              style="margin-right: 10px; font-size: 19px"
            ></i>
            Google ile kaydol
          </a>
          <a
            class="btn shadow mb-4"
            style="background-color: #3b5998; color: #fff"
            (click)="authService.FacebookAuth(returnUrl)"
          >
            <i
              class="fab fa-facebook-square"
              style="margin-right: 10px; font-size: 20px"
            ></i>
            Facebook ile kaydol
          </a>
          <hr />
          <img src="assets/h.png" width="50%" class="center mt-4 mb-4" />
        </div>
      </div>
      <div class="col-md-12 center">
        <app-follow-us></app-follow-us>
      </div>
    </div>
    <div class="animated bounceInDown row" *ngIf="status == 'done'">
      <div class="col-md-9 center" style="margin-top: 10%">
        <div
          class="card card-body shadow mb-2"
          style="background-color: #fff; border: none"
        >
          <h3 class="h3-responsive poppins text-center">
            <i class="fas fa-check-circle" style="color: #00c851"></i> E-posta
            gelen kutunuza hesap doğrulama bağlantısı gönderildi
          </h3>
          <hr />
          <p class="poppins">
            Hesabınızı doğrulamak ve kayıt işlemine devam etmek için lütfen
            e-posta gelen kutunuza gönderilen bağlantıya tıklayın.
          </p>
          <p class="poppins mt-4" style="margin-left: 5px">
            <a routerLink="/">Ana sayfaya </a> git
          </p>
        </div>
      </div>
    </div>
  </div>
</main>
