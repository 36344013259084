<div class="poppins" style="padding: 24px !important;">
    <h1 class="mat-dialog-title poppins text-center">Sözleşmeler ve Formlar</h1>
    <div style="margin-top: 1.5rem;">
        <h5 style="font-size: 15px;">
            MESAFELİ SATIŞ SÖZLEŞMESİ ÖN BİLGİ FORMU
        </h5>
        <div class="agreement-div">
            <span [innerHTML]="preliminaryInformationForm"></span>
        </div>
    </div>

    <div style="margin-top: 2.5rem">
        <h5 style="font-size: 15px;">
            MESAFELİ SATIŞ SÖZLEŞMESİ
        </h5>
        <div class="agreement-div">
            <span [innerHTML]="distanceSalesAgreement"></span>
        </div>
    </div>
</div>