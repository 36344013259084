import { Component, OnInit, ViewChild, Inject, OnDestroy } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/core";
import { SwiperOptions } from "swiper";
import "swiper/swiper-bundle.css";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/firestore";
import { Globals } from "../global";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { AuthService } from "../services/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SessionStorageService } from "../services/session-storage.service";
import { Subscription } from "rxjs";
import { LIGHTBOX_EVENT, Lightbox, LightboxConfig, LightboxEvent } from "ngx-lightbox";
SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay, A11y]);

@Component({
  selector: "app-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.scss"],
})
export class ProductComponent implements OnInit, OnDestroy {
  @ViewChild("container") container;
  @ViewChild("img") img;
  isZoomed = false;
  private _album = [];
  pos = { top: 0, left: 0, x: 0, y: 0 };

  constructor(
    private route: ActivatedRoute,
    public db: AngularFirestore,
    private router: Router,
    private title: Title,
    public globals: Globals,
    public authServices: AuthService,
    private _lightboxEvent: LightboxEvent,
    public snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private sessionStorageService: SessionStorageService,
    private _lightbox: Lightbox,
    private _lightboxConfig: LightboxConfig,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  configProducts: SwiperOptions = {
    height: 300,
    breakpoints: {
      1024: {
        slidesPerView: 4,
        spaceBetween: 2,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      320: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    autoHeight: true,
    loop: true,
    speed: 500,
    observer: true,
    observeParents: true,
  };

  shouldHidePadding = true;
  hidePadding = !this.shouldHidePadding;
  loading = false;
  isLoading = false;
  selectedImage: string;
  selectedImageIndex: number;
  productLink: any;
  product: any;
  products: any[] = [];
  spinner: boolean = true;
  categoryId: any;
  category: any;
  images: any[] = [];
  counter: number = 1;
  HDimages;
  id: any;
  user: any;
  adding: boolean = false;

  shippingImage: any;
  productURL: any;
  subscription: Subscription;
  private _subscription: Subscription;

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this._lightboxConfig.alwaysShowNavOnTouchDevices = true;
    this._lightboxConfig.showRotate = false;
    this._lightboxConfig.showZoom = true;
    this._lightboxConfig.disableScrolling = false;
    this.getProduct();
  }

  getProduct() {
    this.subscription = this.route.params.subscribe((query) => {
      this.spinner = true;
      this.product = null;
      this.productLink = query["id"];
      this.counter = 1;
      if (this.productLink) {
        this.db.firestore
          .collection("products")
          .doc(this.productLink)
          .get()
          .then((documentSnapshots) => {
            if (documentSnapshots.exists) {
              this.product = documentSnapshots.data();
              this.product.hover = false;
              this.productURL = window.location.href;
              if (documentSnapshots.data().youtubeLink) {
                this.product.youtubeLink = this.getSafeUrl(documentSnapshots.data().youtubeLink);
              }
              this.categoryId = this.product.categoryId;
              this.db
                .collection("categories")
                .doc(this.categoryId)
                .get()

                .subscribe((res) => {
                  this.category = res.data();
                  this.spinner = false;
                });
              if (this.globals.imagesQuality == "low") {
                this.images = this.product.images;
                this.HDimages = false;
                this.selectedImage = this.images[0];
                this.selectedImageIndex = 0;
              } else if (this.globals.imagesQuality == "high") {
                this.images = this.product.HDimages;
                this.HDimages = true;
                this.selectedImage = this.images[0];
                this.selectedImageIndex = 0;
              }
              for (let i = 0; i < this.images.length; i++) {
                const src = this.images[i];
                const caption = "Halloumoğlu";
                const album = {
                  src: src,
                  caption: caption,
                  thumb: "thumb",
                };
                this._album.push(album);
              }
              this.title.setTitle("Halloumoğlu - " + this.product.nameEN);
              this.getSimilarProducts();
            } else {
              this.spinner = false;
              this.product = null;
            }
          })
          .catch((err) => {
            this.spinner = false;
            this.product = null;
          });
      }
    });
    if (this.authServices.isLoggedIn) {
      let uid = JSON.parse(localStorage.getItem("user")).uid;
      this.db.firestore
        .collection("users")
        .doc(uid)
        .get()

        .then((documentSnapshots: any) => {
          this.user = documentSnapshots.data();
          this.id = this.user.uid;
        });
    }
    this.db.firestore
      .collection("content")
      .doc("shipping")
      .get()

      .then((res) => {
        this.shippingImage = res.data().imageTR;
      });
  }

  open(index: number): void {
    this._lightbox.open(this._album, index);
    this._subscription = this._lightboxEvent.lightboxEvent$.subscribe((event) => this._onReceivedEvent(event));
  }

  openWithSrc(img: string): void {
    const index = this._album.findIndex((el) => el.src == img);

    this._lightbox.open(this._album, index);
    this._subscription = this._lightboxEvent.lightboxEvent$.subscribe((event) => this._onReceivedEvent(event));
  }

  close(): void {
    this._lightbox.close();
  }
  private _onReceivedEvent(event: any): void {
    if (event.id === LIGHTBOX_EVENT.CLOSE) {
      console.log("CLOSED");
      this._subscription.unsubscribe();
    }

    if (event.id === LIGHTBOX_EVENT.OPEN) {
      console.log("OPEN");
    }

    if (event.id === LIGHTBOX_EVENT.CHANGE_PAGE) {
      console.log(event.data);
    }
  }

  chunkArray(arr) {
    let n = 4;
    var chunkLength = Math.max(arr.length / n, 1);
    var chunks = [];
    for (var i = 0; i < n; i++) {
      if (chunkLength * (i + 1) <= arr.length) {
        chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
      }
    }
    return chunks;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onClickZoom(e) {
    this.isZoomed = !this.isZoomed;
    if (this.isZoomed) {
      this.container.nativeElement.style.overflow = "hidden";
      this.img.nativeElement.style.width = "200%";
      this.img.nativeElement.style.cursor = "zoom-out";
      this.img.nativeElement.style.cursor = "zoom-out";
      this.img.nativeElement.style.left = `-${e.clientX}`;
      this.img.nativeElement.style.top = `-${e.clientY}`;
    } else {
      this.container.nativeElement.style.overflow = "hidden";
      this.img.nativeElement.style.width = "100%";
      this.img.nativeElement.style.cursor = "zoom-in";
    }
  }

  onMouseDown(e) {
    this.pos = {
      left: this.container.nativeElement.scrollLeft,
      top: this.container.nativeElement.scrollTop,
      x: e.clientX,
      y: e.clientY,
    };
  }

  mouseMoveHandler(e) {
    const dx = (e.clientX - this.pos.x) * -2;
    const dy = (e.clientY - this.pos.y) * -3;
    this.container.nativeElement.scrollTop = this.pos.top - dy;
    this.container.nativeElement.scrollLeft = this.pos.left - dx;
  }

  onLeave() {
    this.container.nativeElement.style.overflow = "hidden";
    this.img.nativeElement.style.transform = "scale(1)";
    this.img.nativeElement.style.cursor = "zoom-in";
  }

  toggleLess() {
    if (this.counter > 1) {
      this.counter--;
    }
  }

  toggleMore() {
    this.counter++;
  }

  selectImage(id) {
    this.selectedImage = this.images[id];
    this.selectedImageIndex = id;
  }

  selectNextImage(image) {
    let index = this.images.indexOf(image) + 1;
    if (index >= this.images.length) {
      this.selectedImage = this.images[0];
      this.selectedImageIndex = 0;
    } else {
      this.selectedImage = this.images[index];
      this.selectedImageIndex = index;
    }
  }

  getSimilarProducts() {
    this.db.firestore
      .collection("products")
      .where("hidden", "==", false)
      .where("categoryId", "==", this.categoryId)
      .where("stock", ">", 0)
      .get()
      .then((documentSnapshots: any) => {
        for (var i in documentSnapshots.docs) {
          this.products[i] = documentSnapshots.docs[i].data();
          this.products[i].id = documentSnapshots.docs[i].id;
          this.products[i].hover = false;
        }
      });
  }

  get sortData() {
    return this.products.sort((a, b) => {
      return <any>new Date(a.createdAt) - <any>new Date(b.createdAt);
    });
  }

  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  refreshPage() {
    this._document.defaultView.location.reload();
  }

  onClick() {
    if (this.HDimages) {
      localStorage.setItem("imagesQuality", "low");
      setTimeout(() => {
        this.refreshPage();
      }, 500);
    } else {
      localStorage.setItem("imagesQuality", "high");
      setTimeout(() => {
        this.refreshPage();
      }, 500);
    }
  }

  async addToCart() {
    this.adding = true;
    let cart = [];
    if (this.authServices.isLoggedIn) {
      await this.db.firestore
        .collection("users")
        .doc(this.id)
        .get()
        .then((res) => {
          if (res.data().cart) {
            cart = res.data().cart;
          }
        });
      const index = cart.findIndex((item) => {
        return item.productId === this.productLink;
      });
      if (index < 0) {
        let product = {
          productId: this.productLink,
          count: this.counter,
        };
        cart.push(product);
      } else {
        cart[index] = {
          productId: cart[index].productId,
          count: this.counter + cart[index].count,
        };
      }
      await this.db.firestore
        .collection("users")
        .doc(this.id)
        .set(
          {
            cart: cart,
          },
          { merge: true }
        )
        .then((res) => {
          this.openSnackBar("Ürün Sepetinize Eklendi", "Sepete Git");
          this.adding = false;
        });
    } else {
      this.adding = false;
      if (sessionStorage.getItem("cart")) {
        let storageCart = JSON.parse(sessionStorage.getItem("cart"));
        const index = storageCart.findIndex((item) => {
          return item.productId === this.productLink;
        });
        if (index < 0) {
          let product = {
            productId: this.productLink,
            count: this.counter,
          };
          storageCart.push(product);
        } else {
          storageCart[index] = {
            productId: storageCart[index].productId,
            count: this.counter + storageCart[index].count,
          };
        }
        this.sessionStorageService.setItem("cart", storageCart).subscribe((res) => {
          this.openSnackBar("Ürün Sepetinize Eklendi", "Sepete Git");
        });
      } else {
        let product = {
          productId: this.productLink,
          count: this.counter,
        };
        cart.push(product);
        this.sessionStorageService.setItem("cart", cart).subscribe((res) => {
          this.openSnackBar("Ürün Sepetinize Eklendi", "Sepete Git");
        });
      }
    }
  }

  async addToCart2(productId) {
    this.adding = true;
    let cart = [];
    if (this.authServices.isLoggedIn) {
      let uid = JSON.parse(localStorage.getItem("user")).uid;
      await this.db.firestore
        .collection("users")
        .doc(uid)
        .get()
        .then((res) => {
          if (res.data().cart) {
            cart = res.data().cart;
          }
        });
      const index = cart.findIndex((item) => {
        return item.productId === productId;
      });
      if (index < 0) {
        let product = {
          productId: productId,
          count: 1,
        };
        cart.push(product);
      } else {
        cart[index] = {
          productId: cart[index].productId,
          count: 1 + cart[index].count,
        };
      }
      await this.db.firestore
        .collection("users")
        .doc(uid)
        .set(
          {
            cart: cart,
          },
          { merge: true }
        )
        .then((res) => {
          this.openSnackBar("Ürün Sepetinize Eklendi", "Sepete Git");
          this.adding = false;
        });
    } else {
      this.adding = false;
      if (sessionStorage.getItem("cart")) {
        let storageCart = JSON.parse(sessionStorage.getItem("cart"));
        const index = storageCart.findIndex((item) => {
          return item.productId === productId;
        });
        if (index < 0) {
          let product = {
            productId: productId,
            count: 1,
          };
          storageCart.push(product);
        } else {
          storageCart[index] = {
            productId: storageCart[index].productId,
            count: 1 + storageCart[index].count,
          };
        }
        this.sessionStorageService.setItem("cart", storageCart).subscribe((res) => {
          this.openSnackBar("Ürün Sepetinize Eklendi", "Sepete Git");
        });
      } else {
        let product = {
          productId: productId,
          count: 1,
        };
        cart.push(product);
        this.sessionStorageService.setItem("cart", cart).subscribe((res) => {
          this.openSnackBar("Ürün Sepetinize Eklendi", "Sepete Git");
        });
      }
    }
  }

  buyNow() {
    this.addToCart().then(() => {
      this.router.navigate(["/cart"]);
    });
  }

  openSnackBar(message, action) {
    let snackBarRef = this.snackBar.open(message, action, {
      duration: 10000,
      horizontalPosition: "center",
      direction: "ltr",
      panelClass: "snackbar",
    });
    snackBarRef.onAction().subscribe(() => {
      this.router.navigate(["cart"]);
    });
  }
}
