<app-header></app-header>
<main class="main">
  <div class="container" *ngIf="!spinner">
    <!-- <div class="col-md-12">
      <nav aria-label="breadcrumb" style="background-color: #fff">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a class="poppins" routerLink="/"
              ><i class="fas fa-home"></i> Anasayfa</a
            >
          </li>
          <li class="breadcrumb-item">
            <a class="poppins" routerLink="/products/{{ product.categoryId }}">
              <i class="fas fa-cubes"></i>
              {{ product.categoryNameTR }} Ürünlerimiz</a
            >
          </li>
          <li class="breadcrumb-item active poppins" aria-current="page">
            {{ product.nameTR }}
          </li>
        </ol>
      </nav>
    </div> -->
    <div class="row">
      <div class="col-md-12" *ngIf="product">
        <div class="card card-body" style="background-color: #fff">
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-3 col-3 small-img-div">
                  <a *ngFor="let image of images; let i = index" (click)="selectImage(i)">
                    <img src="{{ image }}" class="center small-image mb-2" width="100%" (click)="open(i)" />
                  </a>
                </div>
                <div class="col-md-9 col-9 swiper-container" #container>
                  <img
                    src="{{ selectedImage }}"
                    class="center swiper-image mb-4 display-lg"
                    width="100%"
                    (click)="openWithSrc(selectedImage)"
                    style="cursor: zoom-in; width: 100%; transition: all 0.3s; position: absolute" />
                  <img
                    src="{{ selectedImage }}"
                    class="center swiper-image mb-4 display-sm"
                    width="100%"
                    (click)="openWithSrc(selectedImage)" />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="product">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-long-arrow-left"></i>
                  </div>
                  <mat-slide-toggle [color]="'primary'" [checked]="HDimages" (click)="onClick()">HD </mat-slide-toggle>
                </div>
                <div class="mb-3">
                  <span class="text-uppercase text-muted brand">
                    {{ product.markaTR | uppercase }}
                  </span>
                  <h5 class="text-uppercase mt-3">{{ product.nameTR }}</h5>
                  <div class="price d-flex flex-row align-items-center" *ngIf="product.discountType == 'noDiscount'">
                    <span class="act-price"> {{ product.price }} TL</span>
                  </div>
                  <div class="price d-flex flex-row align-items-center" *ngIf="product.discountType == 'discount'">
                    <span class="act-price" style="color: #ff1100 !important"> {{ product.price - product.discount }} TL</span>
                    <div class="ml-2">
                      <small class="dis-price"> {{ product.price }} TL</small>
                    </div>
                  </div>
                  <div class="price d-flex flex-row align-items-center" *ngIf="product.discountType == 'percent'">
                    <span class="act-price" style="color: #ff1100 !important">
                      {{ product.price - product.price * (product.discount / 100) }}
                      TL</span
                    >
                    <div class="ml-2">
                      <small class="dis-price"> {{ product.price }} TL</small>
                      <span> {{ product.discount }}% İNDİRİM</span>
                    </div>
                  </div>
                </div>
                <p class="about">
                  {{ product.descriptionTR }}
                </p>
                <div class="mt-4">
                  <div class="stepper-input stepper__input">
                    <button class="toggle-less" (click)="toggleLess()">
                      <i class="fas fa-minus"></i>
                    </button>
                    <div class="stepper-input-field" name="range-value" aria-atomic="true" aria-live="polite" aria-relevant="all">
                      {{ counter }}
                    </div>
                    <button class="toggle-more" (click)="toggleMore()" *ngIf="product.stock > counter">
                      <i class="fas fa-plus"></i>
                    </button>
                    <button class="toggle-more" *ngIf="product.stock <= counter" [disabled]="true">
                      <i class="fas fa-plus"></i>
                    </button>
                  </div>
                </div>
                <div
                  class="row mt-4"
                  *ngIf="
                    (category.hidden == false || category.hidden == null) &&
                    !(product.stock <= 0 || product.stock == null || !product.stock)
                  ">
                  <div class="col-lg-6 col-md-6 col-6 mb-2">
                    <div class="cart align-items-center">
                      <button
                        class="btn btn-halloum2 text-uppercase btn-block mr-2 px-4"
                        style="background: green; border: green; font-size: 12px; color: white"
                        (click)="addToCart()"
                        type="button"
                        [disabled]="adding">
                        Sepete Ekle
                      </button>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-6 mb-2">
                    <div class="cart align-items-center">
                      <button
                        class="btn btn-halloum2 text-uppercase btn-block mr-2 px-4"
                        style="background: green; border: green; font-size: 13px; color: #fff !important"
                        (click)="buyNow()"
                        type="button">
                        Hemen Al
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="category.hidden == true || product.stock <= 0 || product.stock == null || !product.stock">
                  <div class="col-lg-6 col-md-12 mt-4 mb-4 center">
                    <div class="cart mt-4 align-items-center">
                      <button
                        class="btn btn-halloum2 text-uppercase btn-block mr-2 px-4"
                        style="font-size: 13px"
                        type="button"
                        [disabled]="true">
                        Stokta yok
                      </button>
                    </div>
                  </div>
                </div>
                <a
                  class="whatsApp-button"
                  style="color: #fff !important"
                  [href]="['https://wa.me/+905300888034/?text=Bu ürünü sipariş etmek istiyorum, yardımcı olur musunuz? ' + productURL]"
                  target="_blank">
                  <i class="fab fa-whatsapp px-2" style="font-size: 18px"></i>
                  <span class="pr-4">WhatsApp ile Sipariş</span>
                </a>
              </div>
              <div class="col-md-12 mt-4" *ngIf="shippingImage">
                <img class="w-100 rounded" src="{{ shippingImage }}" alt="" />
              </div>
            </div>
            <div class="col-md-12">
              <hr class="center" style="width: 75%" />
              <span [innerHTML]="product.extraDescriptionTR"></span>
              <hr class="center" style="width: 75%" />
            </div>
            <div class="col-md-7 mb-4">
              <app-product-table *ngIf="product.specificationsTR.length > 0" [parent_specifications]="product.specificationsTR">
              </app-product-table>
            </div>
            <div class="col-md-5">
              <div class="videowrapper center mb-4" *ngIf="product.youtubeLink" style="position: relative; bottom: 0; left: 0">
                <iframe
                  [src]="product.youtubeLink"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" style="margin-top: 5%" *ngIf="category.hidden == false">
      <h3 class="lemonada text-center mb-4 text-green">Benzer Ürünler</h3>
      <swiper
        [autoHeight]="true"
        [spaceBetween]="50"
        [navigation]="true"
        [pagination]="{ clickable: true }"
        [scrollbar]="{ draggable: true }"
        [autoplay]="{ delay: 1000, disableOnInteraction: false }"
        [config]="configProducts">
        <ng-template swiperSlide *ngFor="let product of products">
          <div class="col-md-12 mb-4 mt-4">
            <div class="card rounded-8px shadow-sm border-0" matBadge="YENİ" matBadgePosition="after" [matBadgeHidden]="!product.new">
              <a [routerLink]="['/product', product.id]">
                <img src="{{ product.images[0] }}" class="img-product" />
              </a>
              <div class="out-of-stock" *ngIf="product.stock <= 0 || product.stock == null || !product.stock">Stokta Yok</div>
              <div class="card-body" style="padding: 0.75rem">
                <div style="height: 55px; overflow: hidden; text-overflow: ellipsis">
                  <a [routerLink]="['/product', product.id]" class="text-green lemonada" style="font-size: 12px">
                    {{ product.nameTR }}
                  </a>
                </div>
                <div class="row" style="align-items: center">
                  <div style="text-align: inherit" class="col-5">
                    <h5
                      class="act-price"
                      style="text-align: inherit; color: #333333; font-size: 12px; margin-bottom: 0px !important"
                      *ngIf="product.discountType == 'noDiscount'">
                      {{ product.price }} TL
                    </h5>
                    <h5
                      class="act-price"
                      style="text-align: inherit; color: #333333; font-size: 12px; margin-bottom: 0px !important"
                      *ngIf="product.discountType == 'discount'">
                      {{ product.price - product.discount }} TL
                      <small class="dis-price">
                        {{ product.price }}
                        TL</small
                      >
                    </h5>
                    <h5
                      class="act-price"
                      style="text-align: inherit; color: #333333; font-size: 12px; margin-bottom: 0px !important"
                      *ngIf="product.discountType == 'percent'">
                      {{ product.price - product.price * (product.discount / 100) }}
                      TL
                      <small class="dis-price"> {{ product.price }} TL</small>
                    </h5>
                  </div>
                  <div style="text-align: end" class="col-7">
                    <a
                      (click)="addToCart2(product.id)"
                      *ngIf="product.stock > 0"
                      class="btn btn-halloum2 btn-block btn-sm poppins"
                      style="background: green; color: white; border: green; font-size: 10px !important"
                      >Ekle+</a
                    >
                    <a
                      *ngIf="product.stock <= 0 || product.stock == null || !product.stock"
                      class="btn btn-halloum2 btn-block btn-sm poppins disabled font-size: 10px !important"
                      >Stokta Yok</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <div class="swiper-pagination"></div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </swiper>
    </div>
  </div>
  <!-- <div class="container" *ngIf="!spinner && !product">
    <h1 class="text-center poppins">
      404
      <br />
      <br />
      Hata! Aradığınız sayfa mevcut değil.
      <br />
      <br />
      <a routerLink="/"
        >Ana sayfaya geri dön <i class="fas fa-external-link-alt"></i
      ></a>
    </h1>
  </div> -->

  <!-- spinner  -->

  <div class="container" *ngIf="spinner">
    <div class="col-md-12">
      <div class="card card-body" style="background-color: #fff">
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-3 col-3 small-img-div">
                <a>
                  <img style="height: 125px; width: 90px" class="center small-image mb-2 ph-picture" width="100%" />
                </a>
                <a>
                  <img style="height: 125px; width: 90px" class="center small-image mb-2 ph-picture" width="100%" />
                </a>
                <a>
                  <img style="height: 125px; width: 90px" class="center small-image mb-2 ph-picture" width="100%" />
                </a>
                <a>
                  <img style="height: 125px; width: 90px" class="center small-image mb-2 ph-picture" width="100%" />
                </a>
              </div>
              <div class="col-md-9 col-9 swiper-container" #container>
                <img style="height: 400px" class="center swiper-image mb-4 display-lg ph-picture" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center ph-col-6 big"></div>
            </div>
            <div class="ph-col-3">
              <div class="ph-row">
                <div class="ph-col-6 big"></div>
                <div class="ph-col-4 empty big"></div>
                <div class="ph-col-2 big"></div>
                <div class="ph-col-4"></div>
                <div class="ph-col-8 empty"></div>
                <div class="ph-col-6"></div>
                <div class="ph-col-6 empty"></div>
                <div class="ph-col-12"></div>
              </div>
              <div class="ph-row">
                <div class="ph-col-6 big"></div>
                <div class="ph-col-4 empty big"></div>
                <div class="ph-col-2 big"></div>
                <div class="ph-col-4"></div>
                <div class="ph-col-8 empty"></div>
                <div class="ph-col-6"></div>
                <div class="ph-col-6 empty"></div>
                <div class="ph-col-12"></div>
              </div>
              <div class="ph-row">
                <div class="ph-col-6 big"></div>
                <div class="ph-col-4 empty big"></div>
                <div class="ph-col-2 big"></div>
                <div class="ph-col-4"></div>
                <div class="ph-col-8 empty"></div>
                <div class="ph-col-6"></div>
                <div class="ph-col-6 empty"></div>
                <div class="ph-col-12"></div>
              </div>
              <div class="ph-row">
                <div class="ph-col-6 big"></div>
                <div class="ph-col-4 empty big"></div>
                <div class="ph-col-2 big"></div>
                <div class="ph-col-4"></div>
                <div class="ph-col-8 empty"></div>
                <div class="ph-col-6"></div>
                <div class="ph-col-6 empty"></div>
                <div class="ph-col-12"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
<app-footer></app-footer>
