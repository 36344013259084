<div style="padding: 24px !important;">
    <h1 class="mat-dialog-title poppins text-center">Yeni adres ekleyin</h1>
    <form class="contact-form mt-1" novalidate [formGroup]="addressForm" #fform="ngForm" (ngSubmit)="onSubmit()">
        <div class="mat-dialog-content">
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                        <mat-label>Adı</mat-label>
                        <input matInput formControlName="name" type="text" placeholder="Adınızı yazın" required>
                        <mat-error *ngIf="formErrors.name">
                            {{ formErrors.name }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                        <mat-label>Soyadı</mat-label>
                        <input matInput formControlName="lastname" type="text" placeholder="Soyadı yazın" required>
                        <mat-error *ngIf="formErrors.lastname">
                            {{ formErrors.lastname }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                        <mat-label>Telefon</mat-label>
                        <input matInput formControlName="phone" type="tel" placeholder="Örn. 90 531 234 56 78"
                            pattern="[0-9]*" required>
                        <mat-error *ngIf="formErrors.phone" style="font-size: 11px;">
                            {{ formErrors.phone }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                        <mat-label>Adres başlığı</mat-label>
                        <input matInput formControlName="addressTitle" type="text" placeholder="Örn. Ev adresi"
                            required>
                        <mat-error *ngIf="formErrors.addressTitle">
                            {{ formErrors.addressTitle }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                        <mat-label>İl</mat-label>
                        <mat-select class="poppins" formControlName="city">
                            <mat-option class="poppins" *ngFor="let city of citites; let i = index"
                                value="{{city['name']}}" (click)="cityChosen(i)">
                                <span style="margin-right: 10px;">{{i+1 | number:'2.0'}}</span> {{city["name"] |
                                titlecase}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                        <mat-label>İlçe</mat-label>
                        <mat-select class="poppins" formControlName="district">
                            <mat-option class="poppins" *ngFor="let district of districts;" value="{{district}}">
                                {{district | titlecase}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                <div class="col-md-12">
                    <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                        <mat-label>Adres</mat-label>
                        <textarea matInput formControlName="address" placeholder="Detaylı Adresiniz Yazınız" rows="2"
                            required></textarea>
                        <mat-error *ngIf="formErrors.address">
                            {{ formErrors.address }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="mat-dialog-actions" style="margin-bottom: 0px !important;">
            <div class="container-fluid">
                <div class="row poppins" style="color: #fff;">
                    <div class="col-md-6 col-6">
                        <button class="btn btn-success btn-block" type="submit" [disabled]="addressForm.invalid"
                            cdkFocusInitial>Kaydet</button>
                    </div>
                    <div class="col-md-6 col-6">
                        <button class="btn btn-secondary btn-block" type="button" (click)="onNoClick()">İptal</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>