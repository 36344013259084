<app-header></app-header>
<div *ngIf="!showSpinner">
  <main class="main">
    <div class="container-fluid">
      <div class="alert alert-danger" role="alert" *ngIf="!emailVerified">
        Hesabınız doğrulanmadı, size bir hesap doğrulama E-postası gönderdik, lütfen E-postanızı kontrol edin. Hesap doğrulama E-postasını
        tekrar göndermek için
        <a (click)="SendVerificationMess()" class="alert-link alert-a">burayı tıklayın</a>.
      </div>
      <div class="alert alert-success" role="alert" *ngIf="verificationSent">
        Size tekrar doğrulama E-postası gönderdik; E-posta gelen kutunuzu kontrol edin!
      </div>
      <div class="col-xl-11 col-md-12 center">
        <div class="row mb-4">
          <div class="col-xl-2 col-lg-2 col-md-3 col-12 mt-2">
            <app-navigator-list></app-navigator-list>
          </div>
          <div class="col-xl-10 col-lg-10 col-md-9 col-12 mt-2 mb-2" *ngIf="cartProducts.length > 0 && !showSpinner">
            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-12 col-12 display-sm mb-2">
                <div class="card card-body" style="padding: 0.65rem !important">
                  <div class="row mt-1" style="align-items: center">
                    <div class="col-7">
                      <h6 class="text-uppercase text-green poppins" style="font-size: 16px; margin-bottom: 0px; padding-left: 5px">
                        Ürün ({{ cartProducts.length }}):
                      </h6>
                    </div>
                    <div class="col-5" dir="ltr">
                      <div class="price d-flex flex-row align-items-center" style="font-size: 15px">
                        <span class="act-price">{{ totalBeforeDiscount | currency : " TRY " }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="align-items: center" *ngIf="totalBeforeDiscount != total">
                    <div class="col-7">
                      <h6 class="text-uppercase text-green poppins" style="font-size: 16px; margin-bottom: 0px; padding-left: 5px">
                        İndirim:
                      </h6>
                    </div>
                    <div class="col-5" dir="ltr">
                      <div class="price d-flex flex-row align-items-center" style="font-size: 15px">
                        <span class="act-price" style="color: #dc3545">{{ totalBeforeDiscount - total | currency : " TRY- " }}</span>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row mb-1" style="align-items: center">
                    <div class="col-7">
                      <h6 class="text-uppercase text-green poppins" style="font-size: 16px; margin-bottom: 0px; padding-left: 5px">
                        Toplam:
                      </h6>
                    </div>
                    <div class="col-5" dir="ltr">
                      <div class="price d-flex flex-row align-items-center" style="font-size: 15px">
                        <span class="act-price">{{ total | currency : " TRY " }}</span>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row" style="align-items: center">
                    <div class="col-7">
                      <h6 class="text-uppercase text-green poppins" style="font-size: 12px; margin-bottom: 0px">Kargo Ücreti:</h6>
                    </div>
                    <div class="col-5" dir="ltr">
                      <div class="price d-flex flex-row align-items-center" style="font-size: 13px">
                        <span class="act-price">{{ shipping.shippingCost | currency : " TRY " }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2" style="align-items: center" *ngIf="total >= shipping.freeShippingLimit">
                    <div class="col-7">
                      <h6 class="text-uppercase text-green poppins" style="font-size: 12px; margin-bottom: 0px">
                        {{ shipping.freeShippingLimit | currency : " TRY " }} ve üzeri alışverişlerde kargo bedava!
                      </h6>
                    </div>
                    <div class="col-5" dir="ltr">
                      <div class="price d-flex flex-row align-items-center" style="font-size: 13px">
                        <span class="act-price" style="color: #dc3545">{{ shipping.shippingCost | currency : " TRY -" }}</span>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row mb-2">
                    <div class="col-7">
                      <h6 class="poppins">Toplam <span style="font-size: 0.8rem">(TRY)</span></h6>
                    </div>
                    <div class="col-5" *ngIf="total >= shipping.freeShippingLimit">
                      <h6 class="text-gold text-bold">
                        {{ total | currency : " TRY " }}
                      </h6>
                    </div>
                    <div class="col-5" *ngIf="total < shipping.freeShippingLimit">
                      <h6 class="text-gold text-bold">
                        {{ total + shipping.shippingCost | currency : " TRY " }}
                      </h6>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-8 center" *ngIf="getStepper">
                    <button
                      class="btn btn-success btn-rounded text-uppercase btn-block btn-sm poppins"
                      *ngIf="checkboxAgreement && !startPayment"
                      style="font-size: 13px; color: #fff !important"
                      type="button"
                      (click)="checkoutPayment()">
                      Öde {{ getTotalWithShipping() | currency : " TRY " }}
                    </button>
                    <button
                      class="btn btn-success btn-rounded text-uppercase btn-block btn-sm poppins disabled"
                      *ngIf="checkboxAgreement && startPayment"
                      style="font-size: 13px; color: #fff !important"
                      type="button">
                      Öde {{ getTotalWithShipping() | currency : " TRY " }}
                      <i style="margin-left: 3px" class="fa fa-spinner fa-spin fa-lg"></i>
                    </button>
                    <button
                      class="btn btn-success btn-rounded text-uppercase btn-block btn-sm poppins disabled"
                      *ngIf="!checkboxAgreement"
                      style="font-size: 13px; color: #fff !important"
                      type="button">
                      Öde {{ getTotalWithShipping() | currency : " TRY " }}
                    </button>
                  </div>
                  <div class="col-lg-12 col-md-8 center" *ngIf="!getStepper">
                    <button
                      class="btn btn-success btn-rounded text-uppercase btn-block btn-sm poppins"
                      style="font-size: 13px; color: #fff !important"
                      type="button"
                      (click)="loadUserAddresses()">
                      Ödemeye Hazırım
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-xl-8 col-lg-8 col-md-12 col-12" *ngIf="!getStepper">
                <div class="card mb-2" *ngFor="let product of cartProducts; let i = index">
                  <div class="card-body" style="padding: 0.65rem" *ngIf="product">
                    <div class="row" style="align-items: center">
                      <div class="col-xl-1 col-lg-1 col-md-1 col-3 text-center">
                        <button
                          (click)="deleteCartProduct(i)"
                          mat-icon-button
                          aria-label="Example icon button with a home icon"
                          style="color: #000; font-size: 15px; width: 100%">
                          <i class="fas fa-trash-alt"></i>
                        </button>
                      </div>
                      <div class="col-xl-2 col-lg-2 col-md-2 col-5">
                        <a [routerLink]="'/product/' + product.id">
                          <img
                            *ngIf="product.images"
                            [src]="product.images[0]"
                            class="center swiper-image"
                            style="border-radius: 4px !important; width: 100%" />
                        </a>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                        <span class="text-uppercase text-muted brand w-100">
                          {{ product.markaTR | uppercase }}
                        </span>
                        <a [routerLink]="'/product/' + product.id">
                          <p class="text-uppercase mt-1 text-green" style="font-size: 13px; margin-bottom: 0.25rem">
                            <b>{{ product.nameTR }}</b>
                          </p>
                        </a>
                        <div class="price d-flex flex-row align-items-center" *ngIf="product.discountType == 'noDiscount'">
                          <span class="act-price">
                            {{ product.price | currency : " TRY " }} x {{ product.count }} =
                            {{ product.price * product.count | currency : " TRY " }}</span
                          >
                        </div>
                        <div class="price align-items-center" *ngIf="product.discountType == 'discount'">
                          <p style="margin-bottom: 0.25rem">
                            <small class="dis-price" style="color: #dc3545">
                              {{ product.price | currency : " TRY " }} x {{ product.count }} =
                              {{ product.price * product.count | currency : " TRY " }}</small
                            >
                          </p>
                          <span class="act-price" style="color: #28a745">
                            {{ product.price - product.discount | currency : " TRY " }}
                            x {{ product.count }} = {{ (product.price - product.discount) * product.count | currency : " TRY " }}</span
                          >
                        </div>
                        <div class="price align-items-center" *ngIf="product.discountType == 'percent'">
                          <span class="act-price" style="color: #28a745">
                            {{ product.price - product.price * (product.discount / 100) | currency : " TRY " }}
                            x {{ product.count }} =
                            {{ (product.price - product.price * (product.discount / 100)) * product.count | currency : " TRY " }}</span
                          >
                          <div>
                            <small class="dis-price" style="color: #dc3545">
                              {{ product.price * product.count | currency : " TRY " }}</small
                            >
                            <span> {{ product.discount }}% İNDİRİM</span>
                          </div>
                        </div>
                        <div *ngIf="product.count > product.stock" style="color: #dc3545">
                          Bu ürün için yeterli stok bulunmamaktadır.
                          <br />
                          <b>Mevcut Stok {{ product.stock }}</b>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-3 col-md-3 col-6 ml-auto mt-2">
                        <div class="stepper-input stepper__input center" dir="ltr">
                          <button class="toggle-less" (click)="countIncrement(product, -1, i)">
                            <i class="fas fa-minus"></i>
                          </button>
                          <div class="stepper-input-field" name="range-value" aria-atomic="true" aria-live="polite" aria-relevant="all">
                            {{ product.count }}
                          </div>
                          <button class="toggle-more" *ngIf="product.stock > product.count" (click)="countIncrement(product, 1, i)">
                            <i class="fas fa-plus"></i>
                          </button>
                          <button class="toggle-more" *ngIf="product.stock <= product.count" [disabled]="true">
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-8 col-lg-8 col-md-12 col-12" *ngIf="getStepper">
                <div class="row" style="align-items: center">
                  <button
                    mat-icon-button
                    aria-label="Example icon button with a vertical three dot icon"
                    (click)="getStepper = !getStepper">
                    <mat-icon>keyboard_backspace</mat-icon>
                  </button>
                  <div class="col-md-2 col-4 ml-auto">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/halloum-database.appspot.com/o/privacy%2Fkindpng_3669285.png?alt=media&token=3390e7aa-f44e-4dd3-99d0-7aeb787c3859"
                      width="100%"
                      alt="" />
                  </div>
                </div>
                <mat-horizontal-stepper [linear]="true" #stepper (selectionChange)="selectionChange($event)">
                  <mat-step [completed]="addressSelected">
                    <ng-template matStepLabel class="poppins">
                      <span class="display-lg"> Teslimat adresinizi seçin </span>
                    </ng-template>
                    <mat-spinner style="margin-top: 15px" class="center" *ngIf="addresses.length == 0 && showSpinner"> </mat-spinner>
                    <div class="display-sm">
                      <p class="text-center poppins">Teslimat adresinizi seçin</p>
                      <hr />
                    </div>
                    <div
                      class="chooseAddress mat-typography"
                      *ngIf="addresses.length > 0 && !showSpinner"
                      style="padding: 5px !important; margin: 0 !important">
                      <section class="example-section mb-2">
                        <mat-checkbox class="example-margin poppins" style="font-size: 11px" [(ngModel)]="checkboxAddBillingAddress">
                          Fatura ve teslimat adresim aynı.
                        </mat-checkbox>
                      </section>
                      <div class="row mt-2 mb-2" *ngIf="!checkboxAddBillingAddress">
                        <div class="col-md-4 mb-2">
                          <button class="btn btn-outline-warning btn-block btn-sm mt-1" (click)="openDialog()">
                            <i class="fas fa-plus"></i> Fatura Adresi Ekle
                          </button>
                        </div>
                        <div class="col-md-4" *ngIf="billingAddress">
                          <div class="row">
                            <div class="col-lg-10 col-md-10 col-10 text-center" style="display: flex; align-items: center">
                              <p class="poppins" style="margin-bottom: 0px !important">
                                {{ billingAddress.addressTitle }}
                              </p>
                            </div>
                            <div class="col-lg-1 col-md-1 col-2 text-center">
                              <button
                                (click)="deleteBillingAddress()"
                                mat-icon-button
                                aria-label="Example icon button with a home icon"
                                style="color: #000; font-size: 15px; width: 100%">
                                <i class="fas fa-trash-alt"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-6 mb-2">
                          <div class="card shadow-sm poppins" style="height: 100%">
                            <div class="card-header" style="background-color: rgba(0, 0, 0, 0.03) !important">
                              <p style="margin-bottom: 0px; display: flex; justify-content: space-between; align-items: center">
                                <a style="color: #000" routerLink="/my-addresses" [queryParams]="{ returnUrl: '/cart' }">
                                  Yeni bir adres ekleyin
                                </a>
                              </p>
                            </div>
                            <div
                              class="card-body"
                              style="display: flex; align-items: center; justify-content: space-around; font-size: 35px">
                              <a routerLink="/my-addresses" [queryParams]="{ returnUrl: '/cart' }">
                                <i class="fas fa-plus" style="color: #ffc10e"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                        <mat-radio-group class="col-xl-4 col-lg-6 col-md-6 mb-2" *ngFor="let address of addresses; let i = index">
                          <a (click)="selectAddress(i)">
                            <div class="card shadow-sm poppins" [ngClass]="{ selectedCard: address.selected }">
                              <div class="card-header" style="background-color: rgba(0, 0, 0, 0.03) !important">
                                <p style="margin-bottom: 0px; display: flex; justify-content: space-between; align-items: center">
                                  {{ address.addressTitle }}
                                  <mat-radio-button value="{{ address.id }}" [checked]="address.selected"> </mat-radio-button>
                                </p>
                              </div>
                              <div class="card-body" style="font-size: 13px; padding: 0.75rem">
                                <p
                                  style="
                                    margin-bottom: 0px;
                                    font-family: monospace;
                                    letter-spacing: 1px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                  ">
                                  {{ address.name | uppercase }}
                                  {{ address.lastname | uppercase }} <br />
                                  <span style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap">
                                    {{ address.address | uppercase }}
                                  </span>
                                  <br />
                                  {{ address.district | titlecase }}/{{ address.city | titlecase }}
                                  <br />
                                  {{ address.phone }}
                                </p>
                              </div>
                              <div class="container-fluid">
                                <div class="row">
                                  <div class="col-md-9 col-9 center mb-3">
                                    <a
                                      routerLink="/my-addresses"
                                      [queryParams]="{ returnUrl: '/cart' }"
                                      class="btn btn-outline-warning btn-block btn-sm mt-1">
                                      <i class="fas fa-edit"></i> Düzenle
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </mat-radio-group>
                      </div>
                    </div>
                    <div class="poppins text-center" *ngIf="addresses.length == 0 && !showSpinner">
                      <p style="margin-bottom: 5px !important">
                        Üzgünüz!.. Eklenmiş bir adresiniz yok
                        <i class="fas fa-heart-broken"></i>
                      </p>
                      <div class="nav-link waves-light position-relative center">
                        <a class="center" routerLink="/my-addresses" [queryParams]="{ returnUrl: '/cart' }">
                          Adres Ekle <i class="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                    </div>
                    <div class="col-md-6 mt-4 center" *ngIf="addressSelected">
                      <button
                        matStepperNext
                        *ngIf="checkboxAddBillingAddress"
                        class="btn btn-success btn-rounded text-uppercase btn-block mr-2 px-4 poppins"
                        style="font-size: 13px; color: #fff !important"
                        type="button">
                        Sonraki Adım
                      </button>
                      <button
                        *ngIf="!checkboxAddBillingAddress && !billingAddress"
                        class="btn btn-success btn-rounded text-uppercase btn-block mr-2 px-4 poppins disabled"
                        style="font-size: 13px; color: #fff !important"
                        type="button">
                        Sonraki Adım
                      </button>
                      <button
                        matStepperNext
                        *ngIf="!checkboxAddBillingAddress && billingAddress"
                        class="btn btn-success btn-rounded text-uppercase btn-block mr-2 px-4 poppins"
                        style="font-size: 13px; color: #fff !important"
                        type="button">
                        Sonraki Adım
                      </button>
                    </div>
                    <div class="col-md-6 mt-4 center" *ngIf="!addressSelected">
                      <button
                        class="btn btn-success btn-rounded text-uppercase btn-block mr-2 px-4 poppins disabled"
                        style="font-size: 13px; color: #fff !important"
                        type="button">
                        Sonraki Adım
                      </button>
                    </div>
                  </mat-step>
                  <mat-step [completed]="!cardForm.invalid || cardSelected">
                    <ng-template matStepLabel>
                      <span class="display-lg"> Ödeme şeklinizi seçin </span>
                    </ng-template>
                    <div class="display-sm">
                      <p class="text-center poppins">Ödeme şeklinizi seçin</p>
                      <hr />
                    </div>
                    <div class="chooseAddress mat-typography" *ngIf="!showSpinner" style="padding: 5px !important; margin: 0 !important">
                      <div class="row">
                        <div class="col-md-12 mt-4">
                          <a
                            class="poppins link-hover"
                            *ngIf="!addCardForm"
                            (click)="addCardForm = true; cardSelected = false"
                            style="color: #000">
                            <i class="fas fa-plus" style="color: #ffc10e; margin-right: 5px"></i>
                            Yeni Ödeme Şekli Ekle
                          </a>
                          <a
                            class="poppins link-hover"
                            *ngIf="addCardForm"
                            (click)="addCardForm = false; resetCardForm()"
                            style="color: #000">
                            <i class="fas fa-save" style="color: #ffc10e; margin-right: 5px"></i>
                            Veya kayıtlı kartlardan seçin
                          </a>
                        </div>
                        <div class="col-md-12 mb-2 mt-4">
                          <div class="card shadow-sm poppins" style="height: 100%" *ngIf="addCardForm">
                            <div class="card-header" style="background-color: rgba(0, 0, 0, 0.03) !important">
                              <p style="margin-bottom: 0px; display: flex; justify-content: space-between; align-items: center">
                                <a style="color: #000"> Yeni Ödeme Şekli Ekle </a>
                              </p>
                            </div>
                            <div class="card-body" style="display: flex; align-items: center; justify-content: space-around">
                              <form
                                class="contact-form mt-1"
                                novalidate
                                [formGroup]="cardForm"
                                #fform="ngForm"
                                [style.fontSize.px]="13"
                                autocomplete="off">
                                <div class="mat-dialog-content">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                                        <mat-label>Kart Takma Adı</mat-label>
                                        <input matInput formControlName="cardAlias" type="text" placeholder="ÖRN. Visa Kartım" required />
                                        <mat-error *ngIf="formErrors.cardAlias">
                                          {{ formErrors.cardAlias }}
                                        </mat-error>
                                      </mat-form-field>
                                    </div>
                                    <div class="col-md-6">
                                      <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                                        <mat-label>Kart Üzerindeki İsim</mat-label>
                                        <input matInput formControlName="cardHolderName" type="text" placeholder="ÖRN. John Doe" required />
                                        <mat-error *ngIf="formErrors.cardHolderName">
                                          {{ formErrors.cardHolderName }}
                                        </mat-error>
                                      </mat-form-field>
                                    </div>
                                    <div class="col-md-10 col-12">
                                      <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                                        <mat-label>Kart Numarası</mat-label>
                                        <input
                                          matInput
                                          formControlName="cardNumber"
                                          type="tel"
                                          name="cc_number"
                                          placeholder="Kart Numaranız"
                                          ccNumber
                                          #ccNumber="ccNumber"
                                          required />
                                        <mat-error *ngIf="formErrors.cardNumber" style="font-size: 11px">
                                          {{ formErrors.cardNumber }}
                                        </mat-error>
                                      </mat-form-field>
                                    </div>
                                    <div class="col-md-2 col-2 display-lg">
                                      <img
                                        class="w-100"
                                        src="assets/creditCard/visa.svg"
                                        style="object-fit: contain"
                                        *ngIf="(ccNumber.resolvedScheme$ | async) == 'visa'" />
                                      <img
                                        class="w-100"
                                        src="assets/creditCard/mastercard.svg"
                                        style="object-fit: contain"
                                        *ngIf="(ccNumber.resolvedScheme$ | async) == 'mastercard'" />
                                      <img
                                        class="w-100"
                                        src="assets/creditCard/ae.png"
                                        style="object-fit: contain"
                                        *ngIf="(ccNumber.resolvedScheme$ | async) == 'amex'" />
                                    </div>
                                    <div class="col-md-4">
                                      <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                                        <mat-label>Ay</mat-label>
                                        <mat-select class="poppins" formControlName="expireMonth">
                                          <mat-option class="poppins" *ngFor="let month of months" [value]="month">
                                            {{ month }}
                                          </mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                      <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                                        <mat-label>Yıl</mat-label>
                                        <mat-select class="poppins" formControlName="expireYear">
                                          <mat-option class="poppins" *ngFor="let year of years" [value]="year">
                                            {{ year }}
                                          </mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                      <mat-form-field class="poppins w-100" appearance="outline" color="primary">
                                        <mat-label>CVC Kodu</mat-label>
                                        <input
                                          matInput
                                          formControlName="cvc"
                                          type="tel"
                                          placeholder="CVC"
                                          pattern="[0-9]+"
                                          minlength="{{ (ccNumber.resolvedScheme$ | async) == 'amex' ? 4 : 3 }}"
                                          maxlength="{{ (ccNumber.resolvedScheme$ | async) == 'amex' ? 4 : 3 }}"
                                          required />
                                        <mat-error *ngIf="formErrors.cvc" style="font-size: 11px">
                                          <span *ngIf="formErrors.cvc == 'minlength '">
                                            <span *ngIf="(ccNumber.resolvedScheme$ | async) == 'amex'">
                                              CVC Kodu en az 4 rakam olmalıdır
                                            </span>
                                            <span *ngIf="(ccNumber.resolvedScheme$ | async) != 'amex'">
                                              CVC Kodu en az 3 rakamdan oluşmalıdır
                                            </span>
                                          </span>
                                          <span *ngIf="formErrors.cvc == 'maxlength '">
                                            <span *ngIf="(ccNumber.resolvedScheme$ | async) == 'amex'">
                                              CVC Kodu 4 rakamdan az olmalıdır
                                            </span>
                                            <span *ngIf="(ccNumber.resolvedScheme$ | async) != 'amex'">
                                              CVC Kodu 3 rakamdan az olmalıdır
                                            </span>
                                          </span>
                                          <span *ngIf="formErrors.cvc == 'CVC Code is required. '"> CVC Kodu gereklidir. </span>
                                        </mat-error>
                                      </mat-form-field>
                                    </div>
                                    <div class="col-md-12">
                                      <section class="poppins w-100" style="margin-top: 10px">
                                        <mat-checkbox
                                          style="margin-top: 0.5rem"
                                          formControlName="registerCard"
                                          color="primary"
                                          class="example-margin poppins"
                                          >Bu kartı kaydet
                                          <i
                                            style="font-size: 10px; margin-left: 5px"
                                            placement="right"
                                            mdbTooltip="Ödeme Kartları Iyzico'ya kaydedilecek"
                                            class="fas fa-info-circle"></i>
                                        </mat-checkbox>
                                      </section>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                            <div class="px-4">
                              <div class="alert alert-danger mt-1" role="alert">
                                <i class="fas fa-credit-card"></i> Ödeme Kartları
                                <a href="https://www.iyzico.com/" target="_blank" style="color: #1e64ff; font-size: 15px"
                                  ><img src="https://www.iyzico.com/assets/images/content/favicon.png?v=v4.0.281" width="20px" alt="" />
                                  Iyzico</a
                                >'ya kaydedilecek.
                              </div>
                            </div>
                          </div>
                        </div>
                        <mat-radio-group class="col-md-4 mb-2 mt-2" *ngFor="let card of cards; let i = index">
                          <div
                            class="card shadow-sm poppins"
                            *ngIf="!addCardForm"
                            style="height: 100%"
                            [ngClass]="{ selectedCard: card.selected }">
                            <a (click)="selectCard(i)">
                              <div class="card-header" style="background-color: rgba(0, 0, 0, 0.03) !important; display: flex">
                                <a
                                  class="cancel-link center"
                                  style="margin-right: 10px; margin-left: -5px"
                                  (click)="deleteCardByCardToken(card.cardToken)">
                                  <i class="fas fa-trash-alt"></i>
                                </a>
                                <div style="width: 100%">
                                  <p
                                    style="
                                      margin-bottom: 0px;
                                      display: flex;
                                      justify-content: space-between;
                                      align-items: center;
                                      font-size: 13px;
                                    ">
                                    {{ card.cardAlias }}
                                    <mat-radio-button value="{{ card.id }}" [checked]="card.selected"> </mat-radio-button>
                                  </p>
                                </div>
                              </div>
                              <div class="card-body" style="font-size: 13px; padding-bottom: 0px; padding: 0.65rem">
                                <div style="justify-content: space-between; align-items: center; display: flex; flex-wrap: wrap">
                                  <p class="poppins" style="font-size: 11px; margin-bottom: 5px !important">
                                    {{ card.cardBankName }}
                                  </p>
                                  <img src="{{ card.cardAssociationImg }}" style="width: 45px; height: 30px" />
                                </div>
                                <p style="font-size: 10px; text-align: left; margin-bottom: 0px">
                                  {{ card.binNumber }} ****
                                  {{ card.lastFourDigits }}
                                </p>
                              </div>
                            </a>
                          </div>
                        </mat-radio-group>
                        <p
                          *ngIf="cards.length <= 0 && !addCardForm"
                          class="text-center w-100 center poppins text-bold"
                          style="font-size: 15px">
                          Daha önce kaydedilmiş kredi kartınız yok!
                        </p>
                      </div>
                    </div>
                    <div class="col-md-6 mt-4 center">
                      <button
                        *ngIf="!cardSelected && cardForm.invalid"
                        class="cardSelected cardForminvalid btn btn-success btn-rounded text-uppercase btn-block mr-2 px-4 poppins"
                        style="font-size: 13px; color: #fff !important"
                        type="button"
                        disabled>
                        Ödemeyi Tamamlayınız
                      </button>
                      <button
                        matStepperNext
                        *ngIf="cardSelected || !cardForm.invalid"
                        class="cardSelected btn btn-success btn-rounded text-uppercase btn-block mr-2 px-4 poppins"
                        style="font-size: 13px; color: #fff !important"
                        type="button"
                        (click)="addPaymentMethod(); getSelectedCard()">
                        Ödemeyi Tamamlayınız
                      </button>
                    </div>
                  </mat-step>
                  <mat-step>
                    <ng-template matStepLabel>
                      <span class="display-lg"> İncele ve Öde </span>
                    </ng-template>
                    <div class="col-md-12 mt-2 mb-2">
                      <div class="display-sm">
                        <p class="text-center poppins">İncele ve Öde</p>
                        <hr />
                      </div>
                      <div class="card card-body" style="padding: 0.65rem">
                        <p class="poppins" style="font-size: 20px">Sipariş Özeti:</p>
                        <div class="row">
                          <div class="col-7">
                            <h6 class="text-uppercase text-green poppins" style="font-size: 16px; margin-bottom: 0px; padding-left: 5px">
                              Ürün ({{ cartProducts.length }}):
                            </h6>
                          </div>
                          <div class="col-5" dir="ltr">
                            <div class="price d-flex flex-row align-items-center" style="font-size: 15px">
                              <span class="act-price">{{ totalBeforeDiscount | currency : " TRY " }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-1" style="align-items: center" *ngIf="totalBeforeDiscount != total">
                          <div class="col-7">
                            <h6 class="text-uppercase text-green poppins" style="font-size: 16px; margin-bottom: 0px; padding-left: 5px">
                              İndirim:
                            </h6>
                          </div>
                          <div class="col-5" dir="ltr">
                            <div class="price d-flex flex-row align-items-center" style="font-size: 15px">
                              <span class="act-price" style="color: #dc3545">{{ totalBeforeDiscount - total | currency : " TRY- " }}</span>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div class="row mb-2" style="align-items: center">
                          <div class="col-7">
                            <h6 class="text-uppercase text-green poppins" style="font-size: 16px; margin-bottom: 0px; padding-left: 5px">
                              Toplam:
                            </h6>
                          </div>
                          <div class="col-5" dir="ltr">
                            <div class="price d-flex flex-row align-items-center" style="font-size: 15px">
                              <span class="act-price">{{ total | currency : " TRY " }}</span>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div class="row" style="align-items: center">
                          <div class="col-7">
                            <h6 class="text-uppercase text-green poppins" style="font-size: 12px; margin-bottom: 0px">Kargo Ücreti:</h6>
                          </div>
                          <div class="col-5" dir="ltr">
                            <div class="price d-flex flex-row align-items-center" style="font-size: 13px">
                              <span class="act-price">{{
                                shipping.shippingCost
                                  | currency
                                    : "
                                                            TRY "
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2" style="align-items: center" *ngIf="total >= shipping.freeShippingLimit">
                          <div class="col-7">
                            <h6 class="text-uppercase text-green poppins" style="font-size: 12px; margin-bottom: 0px">
                              {{ shipping.freeShippingLimit | currency : " TRY " }}
                              ve üzeri alışverişlerde kargo bedava!
                            </h6>
                          </div>
                          <div class="col-5" dir="ltr">
                            <div class="price d-flex flex-row align-items-center" style="font-size: 13px">
                              <span class="act-price" style="color: #dc3545">{{ shipping.shippingCost | currency : " TRY -" }}</span>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div class="row mb-4">
                          <div class="col-7">
                            <h6 class="poppins" style="font-size: 14px">
                              Toplam
                              <span style="font-size: 0.8rem">(TRY)</span>
                            </h6>
                          </div>
                          <div class="col-5" *ngIf="total >= shipping.freeShippingLimit">
                            <h6 class="text-gold text-bold" style="font-size: 14px">
                              {{ total | currency : " TRY " }}
                            </h6>
                          </div>
                          <div class="col-5" *ngIf="total < shipping.freeShippingLimit">
                            <h6 class="text-gold text-bold" style="font-size: 14px">
                              {{ total + shipping.shippingCost | currency : " TRY " }}
                            </h6>
                          </div>
                        </div>
                        <div class="row payment-checkbox">
                          <div class="col-md-12 col-12 mb-2">
                            <section class="example-section" style="margin-left: 5px">
                              <mat-checkbox
                                [checked]="true"
                                [disabled]="true"
                                color="primary"
                                class="example-margin poppins"></mat-checkbox>
                              <span class="poppins" style="margin-left: 5px; font-size: 12px">
                                3d Secure ile ödeme yapmak istiyorum.
                                <i class="fas fa-shield-alt"></i>
                              </span>
                            </section>
                          </div>
                          <div class="col-md-12 col-12 mb-2">
                            <section class="example-section" style="margin-left: 5px">
                              <mat-checkbox [(ngModel)]="checkboxAgreement" color="primary" class="example-margin"> </mat-checkbox>
                              <span class="poppins" style="margin-left: 5px; font-size: 12px">
                                <a class="link-primary" (click)="openAgreementDialog()">ÖN BİLGİLENDİRME FORMU</a>
                                ve
                                <a class="link-primary" (click)="openAgreementDialog()">MESAFELİ SATIŞ SÖZLEŞMESİ'ni</a>
                                okudum ve kabul ediyorum.
                              </span>
                            </section>
                          </div>
                          <div class="col-md-12 col-12 mb-2" *ngIf="!checkboxAgreement">
                            <button
                              class="btn btn-success btn-rounded text-uppercase btn-block btn-sm poppins disabled"
                              style="font-size: 13px; color: #fff !important"
                              type="button">
                              {{ getTotalWithShipping() | currency : " TRY " }}
                              Öde
                            </button>
                          </div>
                          <div class="col-md-12 col-12 mb-2" *ngIf="checkboxAgreement">
                            <button
                              *ngIf="!startPayment"
                              class="btn btn-success btn-rounded text-uppercase btn-block btn-sm poppins"
                              style="font-size: 13px; color: #fff !important"
                              type="button"
                              (click)="checkoutPayment()">
                              {{ getTotalWithShipping() | currency : " TRY " }}
                              Öde
                            </button>
                            <button
                              *ngIf="startPayment"
                              class="btn btn-success btn-rounded text-uppercase btn-block btn-sm poppins disabled"
                              style="font-size: 13px; color: #fff !important"
                              type="button">
                              {{ getTotalWithShipping() | currency : " TRY " }}
                              Öde
                              <i style="margin-left: 3px" class="fa fa-spinner fa-spin fa-lg"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-step>
                </mat-horizontal-stepper>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-12 display-lg">
                <p class="text-danger bold text-uppercase poppins" style="font-size: 13px; margin-bottom: 5px; padding-left: 5px">
                  {{ shipping.freeShippingLimit | currency : " TRY " }} ve üzeri alışverişlerde kargo bedava!
                </p>
                <div class="card card-body" style="padding: 0.65rem !important">
                  <div class="row mt-3 mb-2" style="align-items: center">
                    <div class="col-7">
                      <h6 class="text-uppercase text-green poppins" style="font-size: 16px; margin-bottom: 0px; padding-left: 5px">
                        Ürün ({{ cartProducts.length }}):
                      </h6>
                    </div>
                    <div class="col-5" dir="ltr">
                      <div class="price d-flex flex-row align-items-center" style="font-size: 15px">
                        <span class="act-price">{{ totalBeforeDiscount | currency : " TRY " }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-1" style="align-items: center" *ngIf="totalBeforeDiscount != total">
                    <div class="col-7">
                      <h6 class="text-uppercase text-green poppins" style="font-size: 16px; margin-bottom: 0px; padding-left: 5px">
                        İndirim:
                      </h6>
                    </div>
                    <div class="col-5" dir="ltr">
                      <div class="price d-flex flex-row align-items-center" style="font-size: 15px">
                        <span class="act-price" style="color: #dc3545">{{ totalBeforeDiscount - total | currency : " TRY - " }}</span>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row mb-2" style="align-items: center">
                    <div class="col-7">
                      <h6 class="text-uppercase text-green poppins" style="font-size: 16px; margin-bottom: 0px; padding-left: 5px">
                        Toplam:
                      </h6>
                    </div>
                    <div class="col-5" dir="ltr">
                      <div class="price d-flex flex-row align-items-center" style="font-size: 15px">
                        <span class="act-price">{{ total | currency : " TRY " }}</span>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row" style="align-items: center">
                    <div class="col-7">
                      <h6 class="text-uppercase text-green poppins" style="font-size: 12px; margin-bottom: 0px">Kargo Ücreti:</h6>
                    </div>
                    <div class="col-5" dir="ltr">
                      <div class="price d-flex flex-row align-items-center" style="font-size: 13px">
                        <span class="act-price">{{ shipping.shippingCost | currency : " TRY " }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2" style="align-items: center" *ngIf="total >= shipping.freeShippingLimit">
                    <div class="col-7">
                      <h6 class="text-uppercase text-green poppins" style="font-size: 12px; margin-bottom: 0px">
                        {{ shipping.freeShippingLimit | currency : " TRY " }} ve üzeri alışverişlerde kargo bedava!
                      </h6>
                    </div>
                    <div class="col-5" dir="ltr">
                      <div class="price d-flex flex-row align-items-center" style="font-size: 13px">
                        <span class="act-price" style="color: #dc3545">{{ shipping.shippingCost | currency : " TRY -" }}</span>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row mb-4">
                    <div class="col-7">
                      <h6 class="poppins">Toplam <span style="font-size: 0.8rem">(TRY)</span></h6>
                    </div>
                    <div class="col-5" *ngIf="total >= shipping.freeShippingLimit">
                      <h6 class="text-gold text-bold">
                        {{ total | currency : " TRY " }}
                      </h6>
                    </div>
                    <div class="col-5" *ngIf="total < shipping.freeShippingLimit">
                      <h6 class="text-gold text-bold">
                        {{ total + shipping.shippingCost | currency : " TRY " }}
                      </h6>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-8 center" *ngIf="getStepper">
                    <button
                      class="btn btn-success btn-rounded text-uppercase btn-block mr-2 px-4 poppins"
                      *ngIf="checkboxAgreement && !startPayment"
                      style="font-size: 13px; color: #fff !important"
                      type="button"
                      (click)="checkoutPayment()">
                      {{ getTotalWithShipping() | currency : " TRY " }} Öde
                    </button>
                    <button
                      class="btn btn-success btn-rounded text-uppercase btn-block mr-2 px-4 poppins disabled"
                      *ngIf="checkboxAgreement && startPayment"
                      style="font-size: 13px; color: #fff !important"
                      type="button">
                      {{ getTotalWithShipping() | currency : " TRY " }} Öde
                      <i style="margin-left: 3px" class="fa fa-spinner fa-spin fa-lg"></i>
                    </button>
                    <button
                      class="btn btn-success btn-rounded text-uppercase btn-block mr-2 px-4 poppins disabled"
                      *ngIf="!checkboxAgreement"
                      style="font-size: 13px; color: #fff !important"
                      type="button">
                      {{ getTotalWithShipping() | currency : " TRY " }} Öde
                    </button>
                  </div>
                  <div class="col-lg-12 col-md-8 center" *ngIf="!getStepper">
                    <button
                      class="btn btn-success btn-rounded text-uppercase btn-block mr-2 px-4 poppins"
                      style="font-size: 13px; color: #fff !important"
                      type="button"
                      (click)="loadUserAddresses()">
                      Ödemeye Hazırım
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-10 col-lg-10 col-md-9 col-12 mt-2 mb-2" *ngIf="cartProducts.length == 0 && !showSpinner">
            <div class="card card-body">
              <div class="m-2 text-center poppins h4 h4-responsive" style="color: #000">
                <p class="poppins">Sepetiniz Boş <i class="fas fa-heart-broken"></i></p>
                <div style="width: 100px" class="nav-link waves-light position-relative center" routerLink="/cart">
                  <i class="fas fa fa-shopping-cart fa-lg"></i>
                </div>
                <hr />
                <p>Hemen Alışverişe Başlayın! <br /></p>
                <a class="mt-4" routerLink="/products/ALL" target="_blank"
                  >Tüm Ürünlerimizi İnceleyin <i class="fas fa-external-link-alt"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-xl-10 col-lg-10 col-md-9 col-12 mt-2 mb-2" *ngIf="showSpinner">
            <mat-spinner style="margin-top: 15px" class="center"> </mat-spinner>
          </div>
        </div>
      </div>
    </div>
    <div class="container" style="margin-top: 5%">
      <p class="text-green text-center" style="font-size: 50px"></p>
      <h4 class="lemonada text-center mb-4 text-green">Önerilen Ürünler</h4>
      <swiper
        [autoHeight]="true"
        [spaceBetween]="50"
        [navigation]="true"
        [pagination]="{ clickable: true }"
        [scrollbar]="{ draggable: true }"
        [autoplay]="{ delay: 1000, disableOnInteraction: false }"
        [config]="configProducts">
        <ng-template swiperSlide *ngFor="let product of suggestedProducts">
          <div class="col-md-12 mb-4 mt-4">
            <div class="card rounded shadow border-0" matBadge="YENİ" matBadgePosition="after" [matBadgeHidden]="!product.new">
              <a href="/product/{{ product.id }}">
                <img src="{{ product.images[0] }}" class="img-product" [ngStyle]="{ 'background-color': product.dominantColor }" />
              </a>
              <div class="out-of-stock" *ngIf="product.stock <= 0 || product.stock == null || !product.stock">Stokta Yok</div>
              <div class="card-body" style="padding: 0.75rem">
                <div style="height: 55px; overflow: hidden; text-overflow: ellipsis">
                  <a href="/product/{{ product.id }}" class="text-green lemonada" style="font-size: 12px">
                    {{ product.nameTR }}
                  </a>
                </div>
                <div class="row" style="align-items: center">
                  <div class="col-4">
                    <h5
                      class="act-price"
                      style="text-align: center; color: #333333; font-size: 12px; margin-bottom: 0px !important"
                      *ngIf="product.discountType == 'noDiscount'">
                      {{ product.price }} TL
                    </h5>
                    <h5
                      class="act-price"
                      style="text-align: center; color: #333333; font-size: 12px; margin-bottom: 0px !important"
                      *ngIf="product.discountType == 'discount'">
                      {{ product.price - product.discount }} TL
                      <small class="dis-price">
                        {{ product.price }}
                        TL</small
                      >
                    </h5>
                    <h5
                      class="act-price"
                      style="text-align: center; color: #333333; font-size: 12px; margin-bottom: 0px !important"
                      *ngIf="product.discountType == 'percent'">
                      {{ product.price - product.price * (product.discount / 100) }}
                      TL
                      <small class="dis-price"> {{ product.price }} TL</small>
                    </h5>
                  </div>
                  <div style="text-align: end" class="col-8">
                    <a
                      (click)="addToCart2(product.id)"
                      style="text-align: right; background: green; color: white; border: green"
                      *ngIf="product.stock > 0"
                      class="btn btn-halloum2 btn-sm poppins"
                      >Eklemek+</a
                    >
                    <a
                      *ngIf="product.stock == 0 || product.stock == null || !product.stock"
                      style="background: green; color: white; border: green"
                      class="btn btn-halloum2 btn-block btn-sm poppins disabled"
                      >Stoklar tükendi</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <div class="swiper-pagination"></div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </swiper>
    </div>
  </main>
</div>

<div *ngIf="showSpinner">
  <div class="view w-100 ph-item">
    <div class="ph-col-12">
      <div style="height: 600px" class="ph-picture" width="100%"></div>
    </div>
  </div>
</div>
<app-footer></app-footer>
