<form class="form-class">
  <input
    style="width: 100%; border-radius: 10px 100px 100px 10px"
    class="input-class"
    type="text"
    placeholder=" ara"
    [formControl]="myControl"
    [matAutocomplete]="auto"
    (keyup)="onKeySearch($event)" />
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option class="mat-option" *ngFor="let product of filteredProducts | async" [value]="product.name">
      <a routerLink="/product/{{ product.productId }}" class="poppins text-green">
        <div style="align-items: center; display: flex">
          <div>
            <img style="height: 50px; width: 50px; object-fit: contain" class="center" src="{{ product.image }}" />
          </div>
          <div>
            <span class="hit-name font-size">{{ product.nameTR }}</span> <br />
            <small class="hit-description">{{ product.categoryNameTR }}</small>
          </div>
        </div>
      </a>
    </mat-option>
  </mat-autocomplete>
</form>
