import { Component, OnInit, OnDestroy } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AuthService } from "../services/auth.service";
import { SessionStorageService } from "../services/session-storage.service";

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.scss"],
})
export class ProductsComponent implements OnInit, OnDestroy {
  categoryLink: any;
  shouldHidePadding = true;
  hidePadding = !this.shouldHidePadding;
  products: any[] = [];
  category: any;
  categories: any[] = [];
  shownCategoriesIds: string[] = [];
  horizontal: string;
  adding: boolean = false;
  subscription: Subscription;
  spinner: boolean = true;

  constructor(
    private route: ActivatedRoute,
    public db: AngularFirestore,
    private title: Title,
    private router: Router,
    public authServices: AuthService,
    public snackBar: MatSnackBar,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.getProducts();
  }

  getProducts() {
    this.db.firestore
      .collection("categories")
      .where("hidden", "==", false)
      .orderBy("createdAt", "asc")
      .get()
      .then((documentSnapshots: any) => {
        for (var i in documentSnapshots.docs) {
          this.categories[i] = documentSnapshots.docs[i].data();
          this.categories[i].id = documentSnapshots.docs[i].id;
          if (this.categories[i].hidden == false) {
            this.shownCategoriesIds.push(this.categories[i].id);
          }
        }

        this.subscription = this.route.params.subscribe((query) => {
          this.spinner = true;
          this.category = null;
          this.horizontal = null;
          this.products = [];
          this.categoryLink = query["name"];
          this.db.firestore
            .collection("categories")
            .doc(this.categoryLink)
            .get()
            .then((documentSnapshots: any) => {
              this.category = documentSnapshots.data();
              this.title.setTitle("Halloumoğlu- " + this.category.nameAR);
              this.horizontal = this.category.horizontal;
              if (this.categoryLink == "all" || this.categoryLink == "ALL" || this.categoryLink == "All") {
                let allProducts = [];
                let promises = [];
                for (let j = 0; j < this.shownCategoriesIds.length; j++) {
                  let promise = this.db.firestore
                    .collection("products")
                    .where("categoryId", "==", this.shownCategoriesIds[j])
                    .where("hidden", "==", false)
                    .where("stock", ">", 0)
                    .get()
                    .then((documentSnapshots: any) => {
                      for (let f = 0; f < documentSnapshots.docs.length; f++) {
                        let product = documentSnapshots.docs[f].data();
                        product.id = documentSnapshots.docs[f].id;
                        product.hover = false;
                        product.isImgLoaded = false;
                        product.addingCart = false;
                        product.counter = 0;
                        allProducts.push(product);
                      }
                    });
                  promises.push(promise);
                }
                Promise.all(promises).then(() => {
                  this.products = this.shuffleArray(allProducts);
                  this.spinner = false;
                });
              } else {
                if (this.category.hidden == false) {
                  this.db.firestore
                    .collection("products")
                    .where("categoryId", "==", this.categoryLink)
                    .where("hidden", "==", false)
                    .where("stock", ">", 0)
                    .get()
                    .then((documentSnapshots: any) => {
                      let allProducts = [];
                      for (var i in documentSnapshots.docs) {
                        let product = documentSnapshots.docs[i].data();
                        product.id = documentSnapshots.docs[i].id;
                        product.hover = false;
                        product.addingCart = false;
                        product.counter = 0;
                        allProducts.push(product);
                      }
                      this.products = this.shuffleArray(allProducts);
                      this.spinner = false;
                    });
                }
              }
            });
        });
      });
  }

  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async addToCart(productId) {
    this.adding = true;
    let cart = [];
    if (this.authServices.isLoggedIn) {
      let uid = JSON.parse(localStorage.getItem("user")).uid;
      await this.db.firestore
        .collection("users")
        .doc(uid)
        .get()
        .then((res) => {
          if (res.data().cart) {
            cart = res.data().cart;
          }
        });
      const index = cart.findIndex((item) => {
        return item.productId === productId;
      });
      if (index < 0) {
        let product = {
          productId: productId,
          count: 1,
        };
        cart.push(product);
      } else {
        cart[index] = {
          productId: cart[index].productId,
          count: 1 + cart[index].count,
        };
      }
      await this.db.firestore
        .collection("users")
        .doc(uid)
        .set(
          {
            cart: cart,
          },
          { merge: true }
        )
        .then((res) => {
          this.openSnackBar("Ürün Sepetinize Eklendi", "Sepete Git");
          this.adding = false;
        });
    } else {
      this.adding = false;
      if (sessionStorage.getItem("cart")) {
        let storageCart = JSON.parse(sessionStorage.getItem("cart"));
        const index = storageCart.findIndex((item) => {
          return item.productId === productId;
        });
        if (index < 0) {
          let product = {
            productId: productId,
            count: 1,
          };
          storageCart.push(product);
        } else {
          storageCart[index] = {
            productId: storageCart[index].productId,
            count: 1 + storageCart[index].count,
          };
        }
        this.sessionStorageService.setItem("cart", storageCart).subscribe((res) => {
          this.openSnackBar("Ürün Sepetinize Eklendi", "Sepete Git");
        });
      } else {
        let product = {
          productId: productId,
          count: 1,
        };
        cart.push(product);
        this.sessionStorageService.setItem("cart", cart).subscribe((res) => {
          this.openSnackBar("Ürün Sepetinize Eklendi", "Sepete Git");
        });
      }
    }
  }
  ////////
  toggleMoreBestProducts(i) {
    this.products[i].counter++;
    this.addToCart(this.products[i].id);
  }

  toggleLessBestProducts(i) {
    if (this.products[i].counter > 1) {
      this.products[i].counter--;
      this.addToCart(this.products[i].id);
    }
  }

  addingBestProducts(i) {
    this.products[i].addingCart = true;
    this.products[i].counter++;
    this.addToCart(this.products[i].id);
  }

  openSnackBar(message, action) {
    let snackBarRef = this.snackBar.open(message, action, {
      duration: 10000,
      horizontalPosition: "center",
      direction: "ltr",
      panelClass: "snackbar",
    });
    snackBarRef.onAction().subscribe(() => {
      this.router.navigate(["cart"]);
    });
  }
}
