<hr />
<h3 class="h3-responsive poppins text-center mt-4">Bizi takip edin <i class="fas fa-share-alt" style="margin-left: 5px"></i></h3>
<div class="footer-social">
     <a class="btn-facebook" href="https://www.facebook.com/Halloumoglu.tr/" target="_blank"><i class="fab fa-facebook-square"></i></a>
     <a class="btn-instagram" href="https://www.instagram.com/halloumoglu.tr/" target="_blank"><i class="fab fa-instagram"></i></a>
     <!-- <a class="btn-twitter" href="https://twitter.com/halloumoglu" target="_blank"><i class="fab fa-twitter"></i></a> -->
     <a class="btn-youtube" href="https://www.youtube.com/c/HALLOUMOGLU" target="_blank"><i class="fab fa-youtube"></i></a>
     <a class="btn-linkedin" href="https://www.linkedin.com/company/halloumoglu/" target="_blank"><i class="fab fa-linkedin-in"></i></a>
     <a class="btn-tiktok" href="https://vm.tiktok.com/ZSJPNgqPh/" target="_blank">
          <i class="fab fa-tiktok"></i>
     </a>
</div>
