<app-header></app-header>
<div *ngIf="spinner">
  <mdb-carousel
    [isControls]="true"
    class="carousel slide carousel-fade"
    [animation]="'fade'"
  >
    <mdb-carousel-item>
      <div class="view w-100" *ngIf="userAgreement">
        <img
          style="height: 300px; object-fit: cover"
          class="d-block w-100 img-sm"
          src="{{ userAgreement.turkishImage }}"
          width="100%"
          alt="First slide"
        />
      </div>
    </mdb-carousel-item>
  </mdb-carousel>
  <main class="main">
    <div class="container" *ngIf="userAgreement">
      <!-- <div class="col-md-8">
            <nav aria-label="breadcrumb" style="background-color: #fff;">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a class="poppins" routerLink="/"><i class="fas fa-home"></i> Home</a>
                    </li>
                    <li class="breadcrumb-item active poppins" aria-current="page"><i class="far fa-handshake"></i>
                        Membership Agreement
                    </li>
                </ol>
            </nav>
        </div> -->
      <div *ngIf="userAgreement">
        <mat-accordion *ngIf="isMode">
          <mat-expansion-panel
            *ngFor="let priv of userAgreement.turkish; let i = index"
            [expanded]="selectedPanel === i"
          >
            <mat-expansion-panel-header>
              <mat-panel-title class="mat-panel-title">
                {{ priv.name }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <span [innerHTML]="priv.description | safeHTML"></span>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion *ngIf="!isMode">
          <mat-expansion-panel *ngFor="let priv of userAgreement.turkish">
            <mat-expansion-panel-header>
              <mat-panel-title class="mat-panel-title">
                {{ priv.name }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <span [innerHTML]="priv.description | safeHTML"></span>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div
        class="card card-body"
        style="background-color: #fff"
        *ngIf="!userAgreement.turkish"
      >
        <h2 class="text-center poppins">Henüz Üyelik Sözleşmesi eklenmemiş!</h2>
      </div>
      <mat-spinner
        style="margin-top: 15px"
        class="center"
        *ngIf="!userAgreement"
      >
      </mat-spinner>
    </div>
  </main>
</div>
<div *ngIf="!spinner">
  <div class="view w-100 ph-item">
    <div class="ph-col-12">
      <div style="height: 600px" class="ph-picture" width="100%"></div>
    </div>
  </div>
</div>
<app-footer></app-footer>
