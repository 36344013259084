<app-header></app-header>
<div *ngIf="spinner">
  <mdb-carousel
    [isControls]="true"
    class="carousel slide carousel-fade"
    [animation]="'fade'"
  >
    <mdb-carousel-item>
      <div class="view w-100" *ngIf="about">
        <img
          style="height: 300px; object-fit: cover"
          class="d-block w-100 img-sm"
          src="{{ about.turkishImage }}"
          width="100%"
          alt="First slide"
        />
      </div>
    </mdb-carousel-item>
  </mdb-carousel>
  <main class="main">
    <div class="container">
      <div class="col-md-8">
        <nav aria-label="breadcrumb">
          <!-- <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a class="poppins" routerLink="/"
              ><i class="fas fa-home" color="blue"></i> Home</a
            >
          </li>
          <li class="breadcrumb-item active poppins" aria-current="page">
            <i class="fas fa-info"></i> About
          </li>
        </ol> -->
        </nav>
      </div>
      <div *ngIf="about">
        <span [innerHTML]="about.englishText | safeHTML"></span>
      </div>
    </div>
    <div class="container-fluid" *ngIf="wideVideo">
      <div class="col-md-11 center">
        <div
          class="videowrapper center mb-4"
          style="position: relative; padding: 3%; bottom: 0; left: 0"
        >
          <iframe
            width="100%"
            height="500vw"
            style="border-radius: 10px"
            [src]="wideVideo"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </main>
</div>

<div class="container col-12" *ngIf="!spinner">
  <div class="view w-100 ph-item">
    <div class="ph-col-12">
      <div style="height: 600px" class="ph-picture" width="100%"></div>
    </div>
  </div>
</div>
<app-footer></app-footer>
