<app-header></app-header>
<div *ngIf="!spinner">
  <main class="main">
    <div class="container-fluid">
      <div class="alert alert-danger" role="alert" *ngIf="!emailVerified">
        Hesabınız doğrulanmadı, size bir hesap doğrulama E-postası gönderdik,
        lütfen E-postanızı kontrol edin. Hesap doğrulama E-postasını tekrar
        göndermek için
        <a (click)="SendVerificationMess()" class="alert-link alert-a"
          >burayı tıklayın</a
        >.
      </div>
      <div class="alert alert-success" role="alert" *ngIf="verificationSent">
        Size tekrar doğrulama E-postası gönderdik; E-posta gelen kutunuzu
        kontrol edin!
      </div>
      <div class="col-xl-11 col-md-12 center">
        <div class="row mb-4">
          <div class="col-xl-2 col-lg-2 col-md-3 col-12 mt-2">
            <app-navigator-list></app-navigator-list>
          </div>
          <div class="col-xl-10 col-lg-10 col-md-9 col-12 mt-2 mb-2">
            <div class="row mb-4">
              <div
                class="col-xl-2 col-md-4"
                *ngFor="let card of filterCard; let i = index"
              >
                <div
                  class="card shadow-sm poppins selectedCard mb-2"
                  (click)="selectFilter(i)"
                  [ngClass]="{ selectedCard: card.selected }"
                  style="cursor: pointer"
                >
                  <div class="card-body" style="padding: 0.5rem">
                    <p
                      class="text-center"
                      style="margin-bottom: 0px; font-size: 13px"
                    >
                      {{ card.name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!spinner && myOrders.length > 0">
              <div
                class="card mb-4"
                *ngFor="let order of myOrders; let i = index"
              >
                <div
                  class="card-header"
                  style="background-color: rgba(0, 0, 0, 0.03) !important"
                >
                  <div class="row">
                    <div
                      class="col-lg-3 col-md-6 col-6 text-center poppins mb-3 center"
                    >
                      <p
                        style="
                          font-size: 13px;
                          margin-bottom: 3px !important;
                          color: #00000085;
                        "
                      >
                        Sipariş tarihi
                      </p>
                      <p
                        style="
                          font-size: 13px;
                          margin-bottom: 0px !important;
                          color: #000;
                        "
                      >
                        {{ order.createdAt | date : "dd/MM/yyyy, h:mm a" }}
                      </p>
                    </div>
                    <div
                      class="col-lg-3 col-md-6 col-6 text-center poppins mb-3 center"
                    >
                      <p
                        style="
                          font-size: 13px;
                          margin-bottom: 3px !important;
                          color: #00000085;
                        "
                      >
                        Alıcı
                      </p>
                      <p
                        style="
                          font-size: 13px;
                          margin-bottom: 0px !important;
                          color: #000;
                        "
                      >
                        {{ order.buyer["name"] | uppercase }}
                        {{ order.buyer["surname"] | uppercase }}
                      </p>
                    </div>
                    <div
                      class="col-lg-2 col-md-6 col-6 text-center poppins mb-3 center"
                    >
                      <p
                        style="
                          font-size: 13px;
                          margin-bottom: 3px !important;
                          color: #00000085;
                        "
                      >
                        Sipariş No
                      </p>
                      <p
                        style="
                          font-size: 13px;
                          margin-bottom: 0px !important;
                          color: #000;
                        "
                      >
                        {{ order.cartId }}
                      </p>
                    </div>
                    <div
                      class="col-lg-2 col-md-6 col-6 text-center poppins mb-3 center"
                    >
                      <p
                        style="
                          font-size: 13px;
                          margin-bottom: 3px !important;
                          color: #00000085;
                        "
                      >
                        Toplam
                      </p>
                      <p
                        class="text-gold"
                        style="
                          font-size: 13px;
                          margin-bottom: 0px !important;
                          font-weight: 500;
                        "
                      >
                        {{
                          order.price | currency : " " + order.currency + " "
                        }}
                      </p>
                    </div>
                    <div
                      class="col-lg-2 col-md-6 col-6 text-center poppins mb-3 center"
                    >
                      <p
                        style="
                          font-size: 13px;
                          margin-bottom: 3px !important;
                          color: #00000085;
                        "
                      >
                        Detaylar
                      </p>
                      <p
                        style="
                          font-size: 13px;
                          margin-bottom: 0px !important;
                          color: #000;
                        "
                      >
                        {{ order.itemTransactions.length }} Ürün
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div
                    *ngFor="
                      let product of order.itemTransactions;
                      let j = index
                    "
                  >
                    <div class="row" style="align-items: center">
                      <div class="col-lg-2 col-md-4 col-4 center">
                        <a [routerLink]="'/product/' + product.id">
                          <img
                            [src]="product.image"
                            class="center swiper-image"
                            style="border-radius: 4px !important; width: 55%"
                          />
                        </a>
                      </div>
                      <div class="col-lg-5 col-md-4 col-8 center">
                        <span class="text-uppercase text-muted brand">
                          {{ product.markaTR | uppercase }}
                        </span>
                        <a [routerLink]="'/product/' + product.id">
                          <h6
                            class="text-uppercase mt-1 text-green"
                            style="font-size: 13px"
                          >
                            <b>{{ product.nameTR }}</b>
                          </h6>
                        </a>
                        <div class="price d-flex flex-row align-items-center">
                          <span class="act-price" style="font-size: 15px">
                            {{
                              product.price
                                | currency : " " + order.currency + " "
                            }}
                            x {{ product.count }} =
                            {{
                              product.price * product.count
                                | currency : " " + order.currency + " "
                            }}
                          </span>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-4 center">
                        <div
                          class="w-100 text-center"
                          style="font-size: 12px"
                          *ngIf="product.productStatus == 'in progress'"
                        >
                          <p class="poppins" style="color: #ffc107">
                            <i class="fas fa-hourglass-half"></i>
                            Sipariş Alındı
                            <br />
                            <span>
                              <hr
                                class="center"
                                style="
                                  width: 25%;
                                  border-top: 2px solid rgb(0 0 0);
                                "
                              />
                            </span>
                            <span style="color: #000; font-size: 12px">
                              <span style="color: #00000085">Ödeme Tarihi</span>
                              <br />
                              {{
                                order.createdAt | date : "dd/MM/yyyy, h:mm a"
                              }}
                            </span>
                          </p>
                        </div>
                        <div
                          class="w-100 text-center"
                          style="font-size: 12px"
                          *ngIf="product.productStatus == 'preparing'"
                        >
                          <p class="poppins" style="color: #ffc107">
                            <i class="fas fa-cube"></i>
                            Sipariş Hazırlanıyor
                            <br />
                            <span>
                              <hr
                                class="center"
                                style="
                                  width: 25%;
                                  border-top: 2px solid rgb(0 0 0);
                                "
                              />
                            </span>
                            <span style="color: #000">
                              <span style="color: #00000085">Ödeme Tarihi</span>
                              <br />
                              {{
                                order.createdAt | date : "dd/MM/yyyy, h:mm a"
                              }}
                            </span>
                          </p>
                        </div>
                        <div class="w-100 text-center" style="font-size: 12px">
                          <p
                            class="poppins"
                            style="color: #fb3"
                            *ngIf="product.productStatus == 'on way'"
                          >
                            <i class="fas fa-truck"></i>
                            Siparişiniz Yola Çıktı
                            <br />
                            <span>
                              <hr
                                class="center"
                                style="
                                  width: 25%;
                                  border-top: 2px solid rgb(0 0 0);
                                "
                              />
                              <a href="{{ order.trackingLink }}" target="_blank"
                                >Kargo Takip <i class="fas fa-external-link"></i
                              ></a>
                            </span>
                            <br />
                            <span style="color: #000; font-size: 12px">
                              <span style="color: #00000085">Sevk Tarihi:</span>
                              <br />
                              {{
                                product.shippingDate
                                  | date : "dd/MM/yyyy, h:mm a"
                              }}
                            </span>
                          </p>
                        </div>
                        <div
                          class="w-100 text-center"
                          style="font-size: 12px"
                          *ngIf="product.productStatus == 'delivered'"
                        >
                          <p
                            class="poppins"
                            style="color: #00c851; margin-bottom: 0px"
                          >
                            <i class="fas fa-map-marker-alt"></i>
                            Sipariş Teslim Edildi
                            <br />
                            <br />
                            <span style="color: #000; font-size: 12px">
                              {{
                                product.deliveryDate
                                  | date : "dd/MM/yyyy, h:mm a"
                              }}
                            </span>
                            <span>
                              <hr
                                class="center"
                                style="
                                  width: 25%;
                                  border-top: 2px solid rgb(0 0 0);
                                "
                              />
                            </span>
                          </p>
                          <span *ngIf="difference(product.deliveryDate) > 0">
                            <a
                              class="cancel-link"
                              (click)="
                                click(order, product.id, refundModal);
                                countToRefund = product.count
                              "
                            >
                              <i class="fas fa-undo"></i> Sipariş İade Et
                            </a>
                            <i
                              class="fas fa-info-circle info-icon"
                              placement="right"
                              mdbTooltip="Siparişi {{
                                difference(product.deliveryDate)
                              }} gün içinde iade edebilirsiniz."
                            ></i>
                            <div
                              mdbModal
                              #refundModal="mdbModal"
                              class="modal fade"
                              tabindex="-1"
                              role="dialog"
                              aria-labelledby="myBasicModalLabel"
                              [config]="{
                                backdrop: true,
                                ignoreBackdropClick: false
                              }"
                              style="background-color: #00000048"
                              aria-hidden="true"
                            >
                              <div
                                class="modal-dialog modal-dialog-centered"
                                role="document"
                              >
                                <div class="modal-content">
                                  <div class="modal-header poppins">
                                    <p
                                      class="text-center w-100"
                                      style="color: #000"
                                    >
                                      Bu ürünleri iade etmek istiyor musunuz?
                                    </p>
                                    <button
                                      type="button"
                                      class="close pull-right"
                                      aria-label="Close"
                                      (click)="refundModal.hide()"
                                    >
                                      <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <div *ngFor="let product of filteredArray">
                                      <div
                                        class="row"
                                        style="align-items: center"
                                      >
                                        <div
                                          class="col-lg-2 col-md-4 col-6 center"
                                        >
                                          <a
                                            [routerLink]="
                                              '/product/' + product.id
                                            "
                                          >
                                            <img
                                              [src]="product.image"
                                              class="center swiper-image w-100"
                                              style="
                                                border-radius: 4px !important;
                                              "
                                            />
                                          </a>
                                        </div>
                                        <div
                                          class="col-lg-6 col-md-4 col-6 center text-left"
                                        >
                                          <span
                                            class="text-uppercase text-muted brand"
                                          >
                                            {{ product.markaTR | uppercase }}
                                          </span>
                                          <a
                                            [routerLink]="
                                              '/product/' + product.id
                                            "
                                          >
                                            <h6
                                              class="text-uppercase mt-1 text-green"
                                              style="font-size: 14px"
                                            >
                                              <b>{{ product.nameTR }}</b>
                                            </h6>
                                          </a>
                                        </div>
                                        <div class="col-lg-4">
                                          <div
                                            class="stepper-input stepper__input center"
                                            dir="ltr"
                                          >
                                            <button
                                              class="toggle-less"
                                              (click)="
                                                countIncrement(product, -1)
                                              "
                                            >
                                              <i class="fas fa-minus"></i>
                                            </button>
                                            <div
                                              class="stepper-input-field"
                                              name="range-value"
                                              aria-atomic="true"
                                              aria-live="polite"
                                              aria-relevant="all"
                                            >
                                              {{ countToRefund }}
                                            </div>
                                            <button
                                              class="toggle-more"
                                              (click)="
                                                countIncrement(product, 1)
                                              "
                                            >
                                              <i class="fas fa-plus"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <div class="col-md-12 center">
                                      <div class="row">
                                        <div class="col-md-6 center">
                                          <button
                                            style="background: #f93154"
                                            [disabled]="disabled"
                                            class="btn btn-danger btn-block relative waves-light poppins"
                                            (click)="
                                              refundOrder(
                                                order.id,
                                                i,
                                                product.id,
                                                countToRefund
                                              );
                                              refundModal.hide()
                                            "
                                            mdbWavesEffect
                                          >
                                            {{ countToRefund }} Ürün İade Et
                                          </button>
                                        </div>
                                        <div class="col-md-4 center">
                                          <button
                                            class="btn btn-secondary btn-block waves-light poppins"
                                            aria-label="Close"
                                            (click)="refundModal.hide()"
                                            mdbWavesEffect
                                          >
                                            İptal
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </span>
                        </div>
                        <div
                          class="w-100 text-center"
                          style="font-size: 12px"
                          *ngIf="product.productStatus == 'refund in progress'"
                        >
                          <p class="poppins" style="color: #f93154">
                            <i class="fas fa-undo"></i>
                            İade Talebi Oluşturdu
                            <br />
                            <span>
                              <hr
                                class="center"
                                style="
                                  width: 25%;
                                  border-top: 2px solid rgb(0 0 0);
                                "
                              />
                            </span>
                            <span style="color: #000; font-size: 12px">
                              <span style="color: #00000085"
                                >Talep Tarihi:</span
                              >
                              <br />
                              {{
                                product.requestDate
                                  | date : "dd/MM/yyyy, h:mm a"
                              }}
                            </span>
                          </p>
                        </div>
                        <div
                          class="w-100 text-center"
                          style="font-size: 12px"
                          *ngIf="product.productStatus == 'refunded'"
                        >
                          <p class="poppins" style="color: #f93154">
                            <i class="fas fa-undo"></i>
                            İade Edildi ve Geri Ödeme Yapıldı <br />
                            {{
                              order.refundedPrice
                                | currency : " " + order.currency + " "
                            }}
                            <br />
                            <span>
                              <hr
                                class="center"
                                style="
                                  width: 25%;
                                  border-top: 2px solid rgb(0 0 0);
                                "
                              />
                            </span>
                            <span style="color: #000; font-size: 12px">
                              <span style="color: #00000085">İade Tarihi:</span>
                              <br />
                              {{
                                product.refundDate | date : "dd/MM/yyyy, h:mm a"
                              }}
                            </span>
                          </p>
                        </div>
                        <div
                          class="w-100 text-center"
                          style="font-size: 12px"
                          *ngIf="order.orderStatus == 'canceled'"
                        >
                          <p class="poppins" style="color: #f93154">
                            <i class="fas fa-times"></i>
                            İptal Edildi ve Geri Ödeme Yapıldı
                            <br />
                            <span>
                              <hr
                                class="center"
                                style="
                                  width: 25%;
                                  border-top: 2px solid rgb(0 0 0);
                                "
                              />
                            </span>
                            <span style="color: #000; font-size: 12px">
                              <span style="color: #00000085"
                                >İptal Tarihi:</span
                              >
                              <br />
                              {{
                                order.cancelationDate
                                  | date : "dd/MM/yyyy, h:mm a"
                              }}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr style="width: 75%" class="center" />
                  </div>
                  <div
                    class="col-md-5 center"
                    *ngIf="
                      order.orderStatus == 'in progress' ||
                      order.orderStatus == 'preparing'
                    "
                  >
                    <button
                      class="btn btn-danger btn-block poppins btn-sm"
                      [disabled]="disabled"
                      (click)="cancelOrder(order.id)"
                    >
                      <i class="fas fa-ban"></i> Tüm Siparişleri İptal Et
                      <i
                        class="fas fa-info-circle info-icon"
                        style="
                          color: #fff !important;
                          font-size: 10px;
                          margin-top: 2px;
                          margin-left: 30px;
                          position: absolute;
                        "
                        placement="right"
                        mdbTooltip="Hazırlama işlemi sırasında siparişi iptal edebilirsiniz."
                      ></i>
                    </button>
                  </div>
                  <div
                    class="col-md-5 center"
                    *ngIf="order.orderStatus == 'cancel in progress'"
                  >
                    <button
                      class="btn btn-danger btn-block poppins disabled btn-sm"
                    >
                      <i class="fas fa-ban"></i> İptal Talebi Oluşturdu
                    </button>
                  </div>
                  <div
                    class="col-md-5 center"
                    *ngIf="order.orderStatus == 'canceled'"
                  >
                    <button
                      class="btn btn-danger btn-block poppins disabled btn-sm"
                    >
                      <i class="fas fa-ban"></i> İptal edildi ve Geri Ödeme
                      Yapıldı
                      {{
                        order.cancelationPrice
                          | currency : " " + order.currency + " "
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!spinner && myOrders.length == 0">
              <div class="card card-body">
                <div
                  class="m-5 text-center poppins"
                  style="font-size: 1.75rem; color: #000"
                >
                  <p class="poppins">
                    Henüz Sipariş Yok <i class="fas fa-heart-broken"></i>
                  </p>
                  <div
                    style="width: 100px"
                    class="nav-link waves-light position-relative center"
                    routerLink="/cart"
                  >
                    <i class="fas fa fa-shopping-cart fa-lg"></i>
                  </div>
                  <hr />
                  <p>Hemen Alışverişe Başlayın! <br /></p>
                  <a class="mt-4" routerLink="/products/ALL" target="_blank"
                    >Tüm Ürünlerimizi İnceleyin
                    <i class="fas fa-external-link-alt"></i
                  ></a>
                </div>
              </div>
            </div>
            <mat-spinner class="center mt-4" *ngIf="spinner"> </mat-spinner>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
<div *ngIf="spinner">
  <div class="view w-100 ph-item">
    <div class="ph-col-12">
      <div style="height: 600px" class="ph-picture" width="100%"></div>
    </div>
  </div>
</div>

<app-footer></app-footer>
