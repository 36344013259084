import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: "app-team",
  templateUrl: "./team.component.html",
  styleUrls: ["./team.component.scss"],
})
export class TeamComponent implements OnInit {
  team: any;
  spinner: boolean = false;
  constructor(
    private title: Title,
    public db: AngularFirestore,
    private router: Router
  ) {
    this.title.setTitle("Halloumoğlu - Ekibimiz");
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    var first = this.db.firestore.collection("content").doc("our-team");
    first.get().then((documentSnapshots: any) => {
      this.team = documentSnapshots.data();
    });
    setTimeout(() => {
      this.spinner = true;
    }, 2000);
  }
}
