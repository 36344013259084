import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { userData } from '../shared/userData';
import { AngularFireAuth } from '@angular/fire/auth';
import { Title } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FirebaseService } from '../services/firebase.service';


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  loading = false;
  errMess: string;
  verified: boolean;
  signUpForm: FormGroup;

  status: string = 'notDone';
  profile: userData;
  returnUrl: string;

  formErrors = {
    'name': '',
    'lastname': '',
    'email': '',
    'password': '',
    'confirmPass': '',
  };
  validationMessages = {
    'name': {
      'required': 'Adı gerekli.',
    },
    'lastname': {
      'required': 'Soyadı gerekli.',
    },
    'email': {
      'required': 'Email gereklidir',
      'email': 'E-posta geçerli biçimde değil.'
    },
    'password': {
      'required': 'Şifre gereklidir',
    },
    'confirmPass': {
      'required': 'Şifre Onayla gereklidir',
    },
  };


  constructor(
    public authService: AuthService,
    public db: AngularFirestore,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    private route: ActivatedRoute,
    public ngZone: NgZone,
    public snackBar: MatSnackBar,
    private fb: FormBuilder,
    private firebaseService: FirebaseService,
    private titleService: Title
  ) {
    this.titleService.setTitle("Halloumoğlu - Üye Ol");
  }

  ngOnInit(): void {
    this.createForm();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  createForm() {
    this.signUpForm = this.fb.group({
      name: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      confirmPass: ['', [Validators.required]],
      checkbox: [false],
      emailSubscription: [false],
    }, { validator: this.checkIfMatchingPasswords('password', 'confirmPass') });

    this.signUpForm.valueChanges
      .subscribe(data => this.onValueChanged(data));

    this.onValueChanged(); //(re)set form validation messages 
  }

  onValueChanged(data?: any) {
    if (!this.signUpForm) {
      return;
    }
    const form = this.signUpForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous erroe message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true })
      }
      else {
        return passwordConfirmationInput.setErrors(null);
      }
    }
  }

  onSubmit() {
    this.loading = true;
    this.authService.SignUp(this.signUpForm.value.email, this.signUpForm.value.password).then(
      res => {
        this.db.collection('users').doc(res.uid).set({
          name: this.signUpForm.value.name,
          lastname: this.signUpForm.value.lastname,
          emailSubscription: this.signUpForm.value.emailSubscription,
          locale: 'tr',
          //tc: '11111111111'
        }, { merge: true }).then(async () => {
          this.openSnackBar("Hesabınız başarıyla oluşturuldu", '🙂')
          this.status = 'done';
          if (sessionStorage.getItem('cart')) {
            let storageCart = JSON.parse(sessionStorage.getItem('cart'));
            let uid = res.uid;
            let cart = [];
            for (let i in storageCart) {
              let product = {
                productId: storageCart[i].productId,
                count: storageCart[i].count,
              }
              cart.push(product);
            }
            await this.db.firestore.collection("users").doc(uid).set({
              cart: cart
            }, { merge: true }).then((res) => {
              sessionStorage.removeItem('cart')
            })
          }
          this.loading = false;
          setTimeout(() => {
            this.router.navigate(['profile'])
          }, 10000);
        })
      },
      error => {
        this.loading = false;
        if (error.code == "auth/email-already-in-use") {
          this.errMess = 'E-posta hesabı zaten kullanılmakta';
        }
        else if (error.code == "auth/invalid-email") {
          this.errMess = 'Geçersiz e-posta formatı';
        }
        else if (error.code == "auth/weak-password") {
          this.errMess = 'Şifre en az 6 karakter uzunluğunda olmalıdır';
        }
        else {
          this.errMess = error.message;
        }
      }
    )
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'left',
      direction: 'ltr',
    });
  }

}
