import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  timer: any = null;
  ellapsedTime = 10;
  spinner: boolean = false;
  status: string;
  hide = true;
  loading = false;
  errMess: string;
  errMessVerify: string;
  resMess: string;
  restPassword: boolean = false;
  verifyEmail: boolean = false;
  restPasswordEmail: string;
  signInForm: FormGroup;
  resetPassForm: FormGroup;
  oobCode: string;

  @ViewChild('fform') resetPassFormDirective;

  formErrors = {
    'email': '',
    'password': '',
    'newPassword': '',
  };
  validationMessages = {
    'email': {
      'required': 'E-posta gereklidir',
      'email': 'E-posta geçerli biçimde değil.'
    },
    'password': {
      'required': 'Şifre gereklidir',
    },
    'newPassword': {
      'required': 'Şifre gereklidir',
      'minlength': 'Şifre en az 6 karakter uzunluğunda olmalıdır',
    }
  };

  constructor(
    private route: ActivatedRoute,
    public afAuth: AngularFireAuth,
    public ngZone: NgZone,
    private fb: FormBuilder,
    public authServices: AuthService,
    public router: Router,
    public snackBar: MatSnackBar,
    private titleService: Title) {
    this.titleService.setTitle("Halloumoğlu - kimlik Doğrulama");
  }

  ngOnInit(): void {
    this.createForm();
    this.createForm2();

    this.route.queryParams.subscribe((params) => {
      if (params['mode']) {
        if (params['mode'] == 'verifyEmail') {
          this.verifyEmail = true;
          if (params['oobCode']) {
            var actionCode = params['oobCode'];
            this.afAuth.applyActionCode(actionCode).then((resp) => {
              this.timer = setInterval(() => { this.tick(); }, 1000);
              this.spinner = false;
              this.status = 'verified'
            }).catch((error) => {
              this.status = 'notVerified'
              if (error.code == 'auth/invalid-action-code') {
                this.errMessVerify = 'E-postanızı doğrulama isteğinizin süresi doldu veya bağlantı zaten kullanılmış.'
              }
              console.log("Error", error);
            });
          }
          else {
            this.status = 'invalidURL'
          }
        }
        else if (params['mode'] == 'resetPassword') {
          this.restPassword = true;
          if (params['oobCode']) {
            var actionCode = params['oobCode'];
            this.oobCode = actionCode
            this.afAuth.verifyPasswordResetCode(actionCode).then((email) => {
              this.spinner = false;
              this.restPasswordEmail = email;
              this.status = 'resetPassForm';
            }).catch((error) => {
              this.status = 'notVerified';
              if (error.code == 'auth/invalid-action-code') {
                this.errMessVerify = 'Parolanızı sıfırlama isteğinizin süresi doldu veya bağlantı zaten kullanılmış.'
              }
              console.log("Error", error);
            });
          }
          else {
            this.status = 'invalidURL'
          }
        }
      }
      else {
        this.status = 'invalidURL'
      }
    })

  }

  createForm() {
    this.signInForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

    this.signInForm.valueChanges
      .subscribe(data => this.onValueChanged(data));

    this.onValueChanged(); //(re)set form validation messages 
  }

  createForm2() {
    this.resetPassForm = this.fb.group({
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
    });

    this.resetPassForm.valueChanges
      .subscribe(data => this.onValueChanged(data));

    this.onValueChanged(); //(re)set form validation messages 
  }

  onValueChanged(data?: any) {
    if (!this.signInForm) {
      return;
    }
    const form = this.signInForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous erroe message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  onValueChanged2(data?: any) {
    if (!this.resetPassForm) {
      return;
    }
    const form = this.resetPassForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous erroe message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  tick() {
    if (this.ellapsedTime == 0) {
      clearInterval(this.timer)
      this.router.navigate(['sign-in'])
    }
    else {
      this.ellapsedTime--;
    }
  }

  onClick() {
    this.status = 'SignInForm';
  }

  SignIn() {
    return this.afAuth.signInWithEmailAndPassword(this.signInForm.value.email, this.signInForm.value.password)
      .then((result) => {
        this.ngZone.run(() => {
          this.SendVerificationMail(result.user);
          this.authServices.SignOutWithoutReloading();
        });
      }).catch((error) => {
        if (error.code == 'auth/user-not-found') {
          this.errMess = 'Hesap bulunamadı'
        }
        else if (error.code == "auth/too-many-requests") {
          this.errMess = 'Çok fazla talep var. Lütfen daha sonra tekrar deneyin.';
        }
        else if (error.code == "auth/wrong-password") {
          this.errMess = 'Şifre yanlış';
        }
        else {
          this.errMess = error.message;
        }
      })
  }

  SendVerificationMail(user) {
    this.status = 'emailSent';
    user.sendEmailVerification();
  }

  resetPassword() {
    this.loading = true;
    return this.afAuth.confirmPasswordReset(this.oobCode, this.resetPassForm.value.newPassword)
      .then(() => {
        this.timer = setInterval(() => { this.tick(); }, 1000);
        this.spinner = false;
        this.loading = false;
        this.status = 'passUpdated'
        this.openSnackBar("Şifrenizi başarıyla yeniledık.", '')
        this.resetPassForm.reset({
          newPassword: '',
        });
        this.resetPassFormDirective.resetForm();
      }).catch((error) => {
        this.loading = false;
        if (error.code == 'auth/weak-password') {
          this.errMess = 'Şifre en az 6 karakter uzunluğunda olmalıdır';
        }
        else {
          this.errMess = error.message;
        }
      })
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'left',
      direction: 'ltr',
    });
  }


}
