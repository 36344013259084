<main class="main">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-12 mb-4">
        <div
          class="card card-body shadow"
          style="background-color: #fff; border: none"
        >
          <h3 class="h3-responsive poppins text-center">
            Alışverişe başlamak için oturum açın
          </h3>
          <h5
            *ngIf="verificationStatus == 'emailNotVerified'"
            class="poppins mt-4 text-center"
            style="color: #f93154"
          >
            Hesabınız doğrulanmadı, size bir hesap doğrulama E-postası
            gönderdik, lütfen E-postanızı kontrol edin.
          </h5>
          <h5
            class="text-center poppins mt-4"
            *ngIf="errMess"
            style="color: red; font-weight: 600"
          >
            {{ errMess }}
          </h5>
          <form
            class="contact-form mt-4"
            novalidate
            [formGroup]="signInForm"
            #fform="ngForm"
          >
            <div class="row">
              <div class="col-md-12 mb-2">
                <mat-form-field
                  class="poppins"
                  appearance="outline"
                  color="primary"
                >
                  <mat-label>E-posta</mat-label>
                  <input
                    matInput
                    formControlName="email"
                    #userName
                    type="email"
                    placeholder="E-posta adresiniz"
                    required
                  />
                  <mat-error *ngIf="formErrors.email">
                    {{ formErrors.email }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12 mb-2">
                <mat-form-field
                  class="poppins"
                  appearance="outline"
                  color="primary"
                >
                  <mat-label>Şifre</mat-label>
                  <input
                    matInput
                    formControlName="password"
                    #userPassword
                    type="password"
                    placeholder="Şifreniz"
                    required
                  />
                  <mat-error *ngIf="formErrors.password">
                    {{ formErrors.password }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-12">
              <button
                [hidden]="loading"
                class="btn btn-primary btn-round btn-block btn-halloum mb-2 poppins"
                (click)="SignIn(userName.value, userPassword.value)"
                [disabled]="signInForm.invalid"
                mdbWavesEffect
                type="submit"
              >
                Giriş Yap
              </button>

              <button
                *ngIf="loading"
                class="btn btn-primary btn-round btn-block btn-halloum mb-2 poppins"
                [disabled]="true"
                mdbWavesEffect
                type="submit"
              >
                Giriş Yapılıyor
                <i
                  style="margin-left: 3px"
                  class="fa fa-spinner fa-spin fa-lg"
                ></i>
              </button>

              <div class="row">
                <div class="col-md-6 col-12 poppins mb-2">
                  hesabınız yok mu?
                  <a routerLink="/sign-up"> Hemen hesap oluştur </a>
                </div>
                <div class="col-md-6 col-12 poppins forgot-password mb-2">
                  <a routerLink="/forgot-password" style="color: #005120d3">
                    Şifrenizi mi unuttunuz?
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-lg-4 col-md-8 center">
        <div
          class="card card-body shadow"
          style="background-color: #fff; border: none"
        >
          <h3 class="h3-responsive poppins text-center">Yada</h3>
          <hr />
          <a
            class="btn shadow mt-4 mb-4"
            style="background-color: #4285f4; color: #fff"
            (click)="authService.GoogleAuth(returnUrl)"
          >
            <i
              class="fab fa-google"
              style="margin-right: 10px; font-size: 19px"
            ></i>
            Google ile giriş yap
          </a>
          <a
            class="btn shadow mb-4"
            style="background-color: #3b5998; color: #fff"
            (click)="authService.FacebookAuth(returnUrl)"
          >
            <i
              class="fab fa-facebook-square"
              style="margin-right: 10px; font-size: 20px"
            ></i>
            Facebook ile giriş
          </a>
          <hr />
          <img src="assets/h.png" width="50%" class="center mt-4 mb-4" />
        </div>
      </div>
      <div class="col-md-12 center mt-4">
        <app-follow-us></app-follow-us>
      </div>
    </div>
  </div>
</main>
