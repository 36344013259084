<div class="card card-body div-scroll">
    <div>
        <a mat-list-item class="lemonada text-green" routerLink="/profile">
            <p style="font-size: 13px;">
                <i class="fas fa-user-circle" style="margin-right: 10px;font-size: 15px;"></i>
                Profil
            </p>
        </a>
        <hr class="mt-3 mb-3 dropdown-divider">
    </div>
    <div>
        <a mat-list-item class="lemonada text-green" routerLink="/cart">
            <p style="font-size: 13px;">
                <i class="fas fa-shopping-cart" style="margin-right: 10px;font-size: 15px;"></i>
                Sepetim
            </p>
        </a>
        <hr class="mt-3 mb-3 dropdown-divider">
    </div>
    <div>
        <a mat-list-item class="lemonada text-green" routerLink="/my-orders">
            <p style="font-size: 13px;">
                <i class="fas fa-cubes" style="margin-right: 10px;font-size: 15px;"></i> Siparişlerim
            </p>
        </a>
        <hr class="mt-3 mb-3 dropdown-divider">
    </div>
    <div>
        <a mat-list-item class="lemonada text-green" routerLink="/my-addresses">
            <p style="font-size: 13px;">
                <i class="fas fa-map-marker-alt" style="margin-right: 10px;font-size: 15px;"></i> Adreslerim
            </p>
        </a>
        <hr class="mt-3 mb-3 dropdown-divider">
    </div>
    <div>
        <a mat-list-item class="lemonada text-green" (click)="signOut()">
            <p style="font-size: 13px;">
                <i class="fas fa-sign-out-alt" style="margin-right: 10px;font-size: 15px;"></i> Çıkış
                Yap
            </p>
        </a>
    </div>
</div>