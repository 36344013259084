import { Injectable, NgZone } from '@angular/core';
import { User } from "../shared/user";
import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import { Observable, Subject } from 'rxjs';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: any; // Save logged in user data
  isAuthenticated: Boolean = false;
  email: Subject<string> = new Subject<string>();
  uid: Subject<string> = new Subject<string>();
  user = new Subject<string>();
  authData: any = undefined;
  authToken: string;

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private sessionStorageService: SessionStorageService
  ) {

    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        user.getIdToken(true).then((authToken) => {
          this.authToken = authToken;
        })
        this.afs.collection('users').doc(this.userData.uid).get().subscribe(userInfo => {
          localStorage.setItem('userData', JSON.stringify(userInfo.data()));
        })
        localStorage.setItem('user', JSON.stringify(this.userData));
        this.authData = JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        localStorage.setItem('userData', null);
      }
    })
  }

  SignUp(email, password) {
    return this.afAuth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        this.SendVerificationMail();
        return result.user
      }).catch((error) => {
        throw error
      })
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser.then(u => u.sendEmailVerification())
      .then(() => {

      }).catch((error) => {
        window.alert(error)
      })
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert(' تم الإرسال, يرجى تفقد إيميلكم 🥰');
      }).catch((error) => {
        window.alert(error)
      })
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null) ? true : false;
  }

  // Sign in with Google
  GoogleAuth(returnUrl) {
    return this.afAuth.signInWithPopup(new auth.GoogleAuthProvider())
      .then((result) => {
        this.ngZone.run(() => {
          setTimeout(() => {
            this.router.navigateByUrl(returnUrl);
          }, 250);
        })
        this.SetUserDataWithGoogle(result.user, result.additionalUserInfo);
      }).catch((error) => {
        window.alert(error)
      })
  }

  // Sign in with Facebook
  FacebookAuth(returnUrl) {
    return this.afAuth.signInWithPopup(new auth.FacebookAuthProvider())
      .then((result) => {
        this.ngZone.run(() => {
          setTimeout(() => {
            this.router.navigateByUrl(returnUrl);
          }, 250);
        })
        this.SetUserDataWithFacebook(result.user, result.additionalUserInfo);
      }).catch((error) => {
        window.alert(error)
      })
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['home']);
        })
        this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error)
      })
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
    }
    return userRef.set(userData, {
      merge: true
    })
  }


  async SetUserDataWithGoogle(user, additionalUserInfo) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    let userData: any;
    if (sessionStorage.getItem('cart')) {
      let storageCart = JSON.parse(sessionStorage.getItem('cart'));
      let uid = user.uid
      let cart = [];
      await this.afs.firestore.collection("users").doc(uid).get().then(res => {
        if (res.data().cart) {
          cart = res.data().cart;
        }
        for (let i in storageCart) {
          const index = cart.findIndex(item => {
            return item.productId === storageCart[i].productId;
          })
          if (index < 0) {
            let product = {
              productId: storageCart[i].productId,
              count: storageCart[i].count
            }
            cart.push(product);
          }
          else {
            cart[index] = {
              productId: cart[index].productId,
              count: storageCart[i].count + cart[index].count
            }
          }
        }
      });
      await this.afs.firestore.collection("users").doc(uid).set({
        cart: cart
      }, { merge: true }).then((res) => {
        this.sessionStorageService.removeItem('cart')
      })
    }
    if (additionalUserInfo.isNewUser) {
      userData = {
        uid: user.uid,
        email: user.email,
        image: user.photoURL,
        createdAt: user.metadata.creationTime,
        name: additionalUserInfo.profile["given_name"],
        lastname: additionalUserInfo.profile["family_name"],
        providerId: additionalUserInfo.providerId,
        //tc: '11111111111',
        locale: 'tr',
      }
      return userRef.set(userData, {
        merge: true
      }).then(() => {
        localStorage.setItem('userData', JSON.stringify(userData));
        this.sendUserData(userData)
      })
    }
  }

  async SetUserDataWithFacebook(user, additionalUserInfo) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    let userInfo;
    if (sessionStorage.getItem('cart')) {
      let storageCart = JSON.parse(sessionStorage.getItem('cart'));
      let uid = user.uid
      let cart = [];
      await this.afs.firestore.collection("users").doc(uid).get().then(res => {
        if (res.data().cart) {
          cart = res.data().cart;
        }
        for (let i in storageCart) {
          const index = cart.findIndex(item => {
            return item.productId === storageCart[i].productId;
          })
          if (index < 0) {
            let product = {
              productId: storageCart[i].productId,
              count: storageCart[i].count
            }
            cart.push(product);
          }
          else {
            cart[index] = {
              productId: cart[index].productId,
              count: storageCart[i].count + cart[index].count
            }
          }
        }
      });
      await this.afs.firestore.collection("users").doc(uid).set({
        cart: cart
      }, { merge: true }).then((res) => {
        this.sessionStorageService.removeItem('cart')
      })
    }
    userRef.get().subscribe(res => userInfo = res.data())
    const userData: any = {
      uid: user.uid,
      email: user.email,
      image: user.photoURL,
      createdAt: user.metadata.creationTime,
      name: additionalUserInfo.profile.first_name,
      lastname: additionalUserInfo.profile.last_name,
      providerId: additionalUserInfo.providerId,
      // tc: userInfo.tc ?? '11111111111',
      locale: 'tr',
    }
    return userRef.set(userData, {
      merge: true
    }).then(() => {
      localStorage.setItem('userData', JSON.stringify(userData));
      this.sendUserData(userData)
    })
  }

  sendEmail(name: string) {
    this.email.next(name);
  }

  getEmail(): Observable<string> {
    return this.email.asObservable();
  }

  clearEmail() {
    this.email.next(undefined);
  }

  sendUserData(name: string) {
    this.user.next(name);
  }

  getUserData(): Observable<string> {
    return this.user.asObservable();
  }

  clearUserData() {
    this.user.next(undefined);
  }

  sendUid(name: string) {
    this.uid.next(name);
  }

  getUid(): Observable<string> {
    return this.uid.asObservable();
  }

  clearUid() {
    this.uid.next(undefined);
  }

  destroyUserCredentials() {
    this.authData = undefined;
    this.clearUserData();
    this.clearEmail();
    this.clearUid();
    this.isAuthenticated = false;
    this.userData = undefined;
  }

  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      localStorage.removeItem('userData');
      localStorage.removeItem('uid');
      this.destroyUserCredentials();
      window.location.reload();
    })
  }

  SignOutWithoutReloading() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      localStorage.removeItem('userData');
      localStorage.removeItem('uid');
      this.destroyUserCredentials();
    })
  }

  getToken(): string {
    return this.authToken;
  }

  checkJWTtoken() {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        user.getIdToken(true).then((authToken) => {
          this.authToken = authToken;
        })
      } else {
        localStorage.setItem('user', null);
        localStorage.setItem('userData', null);
      }
    },
      err => {
        console.log("JWT Token invalid: ", err);
        this.destroyUserCredentials();
      })
  }

}
